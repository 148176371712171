
// import AboutBenefits from '../../components/AboutPage/AboutBenefits/AboutBenefits';

import AboutBanner from "../../components/AboutUsPage/AboutBanner/AboutBanner";
import AboutBenefits from "../../components/AboutUsPage/AboutBenefits/AboutBenefits";
import AboutResponsibility from "../../components/AboutUsPage/AboutResponsibility/AboutResponsibility";
import NoNeedLostPets from "../../components/AboutUsPage/NoNeedLostPets/NoNeedLostPets";
import RejestrationBenifits from './../../components/AboutPage/RejestrationBenifits/RejestrationBenifits';

export default function AboutUs() {
  return (
    <div>
        <AboutBanner />
        <NoNeedLostPets />
        {/* <AboutBenefits /> */}
        <RejestrationBenifits />
        <AboutResponsibility />
    </div>
  )
}