import { useState } from 'react';
import './style.css';

export default function ProductDetailAccordian() {
    const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

    const accordionData = [
        {
            title: '¿Cómo funciona?',
            content: `
          <p>Gracias a la plaquita grabada a láser con el nombre de la mascota y tu número de teléfono,
          podrán llamarte o escribirte para indicarte dónde fue encontrado... </p>

          <p>Además, gracias al código RUMP que viene grabado en la plaquita y DNI, podrán 
ingresarlo a nuestra web y ver datos como distrito, enfermedades a tener en cuenta, 
segundo número de contacto y una referencia de por dónde queda su hogar</p>
          `,
        },
        {
            title: 'Código QR rastreable',
            content: `<p>El código QR en la plaquita y DNI también permitirá echar un vistazo al perfil de tu mascotita...</p>
          
         <p> También recibirás una notificación en tu perfil RUMP que te permitirá saber cuándo y 
dónde fue escaneado el código QR.</p>
          `,
        },
        {
            title: 'Válido a nivel nacional e internacional',
            content: `<p>Nuestro registro es válido a nivel nacional e internacional... </p>
          
          <p>Si bien no existe un registro nacional oficial como RENIEC en el caso de los humanos, 
RUMP es el primer registro privado de mascotas en el Perú.</p>

<p>Más de 5 años de trayectoria y 200 mil mascotitas registradas en todo el país.</p>
          `,
        },
    ];

    return (
        <>
            {accordionData.map((data , index) => <div className="product_accordion-item" key={index}  
                onClick={() => toggleAccordion(index)}>
                    <div className="product_accordion-title" onClick={toggleAccordion}>
                        <h3>{data.title}</h3>
                    </div>
                    { (index == openIndex) && <div className="product_accordion-content" dangerouslySetInnerHTML={{__html:data?.content}}></div>}
                </div>)}
        </>
    );
}
