import React, { useState } from 'react';
import './Accordian.css';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderedItems = items.map((item, index) => {
    const active = index === activeIndex ? 'active' : '';

    return (
      <div key={index} className={`accordion-item ${active}`}>
        <div
          className={`title`}
          onClick={() => onTitleClick(index)}
        >
          <i className={`acc_icon ${active ? 'close' : 'plus'}`}></i>
          <p className='text-[#121212]'>{item.title}</p>
        </div>
        <div className={`content`}>
          <p>{item.content}</p>
        </div>
      </div>
    );
  });

  return <div className="accordion">{renderedItems}</div>;
};

export default Accordion;
