import React , {useEffect} from "react";
import "./style.css";
import { bigCircle, looped_arrow } from "../../../assets/svgIcons";

import petCard from "../../../assets/images/petCard.png"


import AOS from "aos";

const LoveProof = () => {



  useEffect(()=>{
    AOS.init({
      duration:600
    })
  },[]);


  return (
    <div className='love_proof_contaienr'>
      <div className='love_proof_content'>
        <div className='lp_left_side' data-aos = "fade-down">
          <h4 className='text-light'>
            <span className='text-dark'>RUMP... </span>Es más que un DNI y lindos accesorios. Es seguridad para tu mascota 
            <span className='text-dark'> y una prueba <span className="b-border ">de amor <span></span></span></span>
          </h4>
          <div className="lp_looped_arrow">
              {looped_arrow}
          </div>
        </div>
        <div className='lp_left_right' data-aos = "fade-up">
          <div className="prodImage">
            {/* <div className="circle_layout">{bigCircle}</div> */}
            <div className="image">
              <img src={petCard} alt="prod" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoveProof;
