import React , {useEffect} from "react";

import image from "../../../assets/images/ownerShip.png";
import "./style.css";
import { orangeOval, ovalIcon2 } from "../../../assets/svgIcons";

import AOS from "aos";

import peruMap from "../../../assets/images/peruMap_no_bg.png"

const OwnerShip = () => {


  useEffect(()=>{
    AOS.init({
      duration:600
    })
  },[]);


  return (
    <div className='ownership_container'>
      <div className='ownership_content'>
        <div className='os_left_side' data-aos = "fade-right">
          <div className='image'>
            <img src={image} alt='' />
          </div>
        </div>
        <div className='os_right_side' data-aos = "fade-left">
          <div className='os_title'>
            <h5>Comprometidos con la</h5>
            <h5 className='text_orange'>tenencia responsable</h5>
          </div>
          <div className='os_content'>
            <div className='os_content_sec'>
              ¿Sabías que una mascota doméstica puede convertirse en un
              animalito callejero <span className="text_orange">en solo 2 días?</span> Muchos de los perros y gatos
              que ves en la calle un día tuvieron un hogar
            </div>
            <div className='os_content_sec'>
              Nuestro objetivo es darle a tu engreido todas las herramientas
              posibles para evitar que se pierda, exponiéndose a accidentes,
              vendedores de animales, enfermedades, ataques, el clima y gente
              con malas intenciones.
            </div>
            <div className='os_content_sec'>
              Ellos cuentas contigo, <span className="text_orange">y tú cuentas con RUMP.</span>
            </div>
          </div>
          <div
          className='oval_button os_oval'
          style={{
            position: "relative",
            fill: "rgb(248, 100, 8)",
          }}
        >
          {ovalIcon2}

          <div className='oval_btn_text'>Nuestra misión
          </div>
        </div>
        </div>
      </div>

      <div className="peru_map_image">
        <img src={peruMap} alt="" />
      </div>
    </div>
  );
};

export default OwnerShip;
