export const questions = [
    {
        question_name:"ًCuál es el sexo de tu mascota?",
        type:"SEXO",
        isImg : true,
        question_options : [
        {
            opt_title : "Todos",
            opt_img :"/download__4_-removebg-preview.png",
        },
        {
            opt_title: "Hembra",
            opt_img :"/download__3_-removebg-preview.png",
        }
        ,{
            opt_title:"Macho",
            opt_img :"/noun-male-33316.png",
        }
    ],
    },
    {
        question_name:"Cuál es el Tamano de tu mascota?",
        type:"TAMANO",
        isImg : false,
        question_options : ["Todos" , "Grande" , "Chico","Mediano"],   
    },
    {
        question_name:"Cuál es el tipo de tu mascota?",
        type:"TIPO",
        isImg : true,
        question_options : [
        {
           opt_title:"Ave",
           opt_img:"/adobtImgs/sk-yeong-MOAzs7daQBU-unsplash-removebg-preview.png"    
        },
        {
            opt_title:"Felino",
            opt_img:"/adobtImgs/cat-removebg-preview.png",
        },
        {
            opt_title:"Canino",
            opt_img:"/adobtImgs/dog-removebg-preview.png",
        },
        {
            opt_title:"Lagomorfo",
            opt_img:"/adobtImgs/Lagomorph-removebg-preview.png",
        },
        {
            opt_title:"Marzupial",
            opt_img:"/adobtImgs/michael-jerrard-mnHs4boXT_0-unsplash-removebg-preview.png",
        },
        {
            opt_title:"Roedor",
            opt_img:"/adobtImgs/ricky-kharawala-adK3Vu70DEQ-unsplash-removebg-preview.png",
        }
    
    ] ,
        // question_options : ["sk-yeong-MOAzs7daQBU-unsplash" , "cat" , "dog","michael-jerrard-mnHs4boXT_0-unsplash","ricky-kharawala-adK3Vu70DEQ-unsplash"],
    },
    {
        question_name:"Cuál es la calificación de tu mascota?",
        type:"CALIFICACION",
        isImg : false,
        question_options:["Todos" , "Agresivo","Amistoso","Discapacitado","Entrenado","Miedoso","peleador"]
    }
]



// export const results= [
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico", // size
//         "TIPO": null, // type
//         "RAZA": null, //race
//         "CALIFICACION": null, //qualification
//         "SEXO": "Macho", // gender
//         "CORREO": null,  //mail
//         "OBSERVACION": null, //Observation
//         "TELEFONO": null,  // phone
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "UWgj6CfyKBg=",
//         "DNI": "00001003",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "MACHIN", // name
//         "APELLIDO": "vacio_", // last name
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo", // color
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(631177200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Qp4DxarHtbQ=",
//         "DNI": "00001776",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Tubo",
//         "APELLIDO": "Encarnacion Quispe",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1506927600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "bupG+WXrLT0=",
//         "DNI": "00001856",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Tugo",
//         "APELLIDO": "Encarnacion Quispe",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1509606000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "luP18ImnQmM=",
//         "DNI": "00004607",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "PERICLES",
//         "APELLIDO": "LUCIO BARRETO",  // last name
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@MESTIZO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1527836400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "7Pniw8ZqSLY=",
//         "DNI": "00001287",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "cliford",
//         "APELLIDO": "Rodríguez medrano",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "74MjTB+0+es=",
//         "DNI": "00004824",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Paco",
//         "APELLIDO": "Valencia Canales",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Negro con caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "p/hpdjcm7kY=",
//         "DNI": "00005723",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "WALLACE",
//         "APELLIDO": "REYES MENDOZA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1484722800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "wzi+G7lWMVU=",
//         "DNI": "00008877",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "VALENTINO",
//         "APELLIDO": "BARRERA REY",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1445238000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "YqzT2GBP0Ek=",
//         "DNI": "00009458",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "TOFFY",
//         "APELLIDO": "RIOS ROJAS",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@MARRON",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1483426800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "6Rg8+LawWOY=",
//         "DNI": "00008394",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "RONY",
//         "APELLIDO": "GOMES FLORES",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Naranja",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1514962800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "wdEW7jgHCfQ=",
//         "DNI": "00004193",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "manchas",
//         "APELLIDO": "valverde egusquiza",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1524553200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "fqCj8G1ys9g=",
//         "DNI": "00010904",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Fredy Mercury",
//         "APELLIDO": "monzon",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "YvbgoOFnfYw=",
//         "DNI": "00002114",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Chiquito",
//         "APELLIDO": "Ordoñez Ordoñez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1448002800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "vCJ8GsbS4wA=",
//         "DNI": "00011383",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "YACO",
//         "APELLIDO": "TUME",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Marron",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chihuahua con pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1277276400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "K8Y8Cl6gE+o=",
//         "DNI": "00007102",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "CHICHO",
//         "APELLIDO": "CARPIO CARPIO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "vacio_",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1269327600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "OQ162OBv8Bg=",
//         "DNI": "00007167",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "VACO",
//         "APELLIDO": "SANCHEZ MANRIQUE",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1421910000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "pciTLNRkoB4=",
//         "DNI": "00002117",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Toby",
//         "APELLIDO": "Ordoñez Lopez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1446793200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "OW4WFlh7Ujs=",
//         "DNI": "00002131",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Cliford",
//         "APELLIDO": "Mamani narciso",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1514358000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Q6LZC0sUaB0=",
//         "DNI": "00002022",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Conchis",
//         "APELLIDO": "Del pino Palomino",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "u/jHLGQy2XM=",
//         "DNI": "00010101",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ODIN",
//         "APELLIDO": "SEVILLANO INFANZON",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Marron",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1531378800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "ziI3yxK25wk=",
//         "DNI": "66558462",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Iron Man ",
//         "APELLIDO": "Robles Cespedes",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Crema ",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "COCKER SPANIEL",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1361257200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "285DBE36B6CAB6C192769B2D22D9150A.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "5v7xq7UQiDQ=",
//         "DNI": "20223362",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Drago Entey",
//         "APELLIDO": "Medrano Valencia",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco con negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cruce con rottweiler",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1442732400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "p0d/qgqcBSw=",
//         "DNI": "00001033",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "LUKAS",
//         "APELLIDO": "GALDOS CANDER",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "AMARILLO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRIOLLA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "MAGFPWQJINI=",
//         "DNI": "12961317",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Boky Firulais De Jesús",
//         "APELLIDO": "Altamirano Changa",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro de panza blanca",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRIOLLA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1491462000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "3DFF232F506693720CAAE97D135FAA54.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "CqkmeXiAUgI=",
//         "DNI": "00011971",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Yellow",
//         "APELLIDO": "Llanos",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRIOLLA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1379919600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "iDEpkCjUiWI=",
//         "DNI": "00009045",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BOYKA",
//         "APELLIDO": "CASTRO MOLINA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "BULL TERRIER",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1522825200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Y1aaMXYSQh8=",
//         "DNI": "00012518",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Aquiles",
//         "APELLIDO": "Guizado alfaro",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Gris y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Unica",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "7vnCELokigU=",
//         "DNI": "00002239",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Hercules",
//         "APELLIDO": "Sanchez Mejia",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Unica",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1407135600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "t9hUnBPyTqQ=",
//         "DNI": "00003021",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "DUKE ALONSO",
//         "APELLIDO": "RUEDA BAZALAR",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cocker",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1360911600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "dInSb7yZaBQ=",
//         "DNI": "00003261",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Precioso",
//         "APELLIDO": "Romani portillo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cocker",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1398841200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "MzLWVdUzbQs=",
//         "DNI": "07306571",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Milo",
//         "APELLIDO": "Aguirre Osorio",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cocker",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1568271600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "DE5BF51B0C91A10A6A8F13750B41BF0A.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "g7p2mh33uYw=",
//         "DNI": "00008342",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "wallo",
//         "APELLIDO": "puquio rodriguez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cocker",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1357110000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Rg0LhZFM89c=",
//         "DNI": "33453913",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Churchill",
//         "APELLIDO": "Gamboa Benavides",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco con Marrón ",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Bulldog Inglés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1631430000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "53EE88509050B0F49C3B2E29FC1008EA.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "tQNW+mRPhhs=",
//         "DNI": "00012108",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Baxter",
//         "APELLIDO": "Alarcon",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Beige",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chow chow",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1550646000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "+syumt9RKP8=",
//         "DNI": "19975767",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Tobby",
//         "APELLIDO": "Pabletich Jordán",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marrón",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chow chow",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1479625200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "zwXR+a4ak+I=",
//         "DNI": "51686141",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Pericles",
//         "APELLIDO": "Valencia Valencia ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Scottish terrier",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1272438000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "4AD38ECF2884A47A5EFA657F3358E635.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "zVnLoqMpJ7o=",
//         "DNI": "14449075",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Rocko",
//         "APELLIDO": "Corvacho Mazza",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "dogo argentino",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1569567600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "556A1F51FE42F64C0E5865070262C2A5.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "KaLcNKnuNQA=",
//         "DNI": "59096629",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Bark",
//         "APELLIDO": "Chamaya Guevara",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco c/ marròn",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Pitbull",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1702796400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "70CEAF1C446496B9CFFE9A29CA1A3770.PNG",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "xNjDz/QqzKw=",
//         "DNI": "00001992",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Rocky",
//         "APELLIDO": "Diaz Saravia",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Sharpei Americano",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1485586800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "pIHBg85etPU=",
//         "DNI": "00004776",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "rambo",
//         "APELLIDO": "sanchez espinoza",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1425970800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "LaiCsjNysVs=",
//         "DNI": "00003596",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "jade",
//         "APELLIDO": "ramirez condori",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1515999600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "MsjYEBxcVCc=",
//         "DNI": "00009762",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "negro",
//         "APELLIDO": "bañez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1291532400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "TaKxB/GjvYk=",
//         "DNI": "00002898",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "principe",
//         "APELLIDO": "grandez vivaz",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1472713200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "mUp+FHnjk28=",
//         "DNI": "00007178",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "COMANDO",
//         "APELLIDO": "ARELLANO ORDOñEZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1422428400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "mAqVMQ3tP9g=",
//         "DNI": "00008170",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ikay",
//         "APELLIDO": "rivas jimenes",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1472108400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "HScS1EcYK18=",
//         "DNI": "10355860",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Spirit",
//         "APELLIDO": " Kaseng Chavez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1640415600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "E8063C89BE028CD593718CBB009441AC.png",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "lonlN4JbPSQ=",
//         "DNI": "00007034",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "luke",
//         "APELLIDO": "briceÑO arrieta",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@cremita",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1373958000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Gzz0O0oIZsk=",
//         "DNI": "00010753",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "KAYSER",
//         "APELLIDO": "MIÑOPE ROJAS",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1261465200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "a4wHOa+h/bM=",
//         "DNI": "00010772",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BORIS",
//         "APELLIDO": "OBANDO SUQUILANDA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1334127600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "6rQX/ZlwwS8=",
//         "DNI": "00005615",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "PANCHO",
//         "APELLIDO": "RIMACHI ORTEGA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1452409200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "eInb5dy+0sM=",
//         "DNI": "00008706",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ARES",
//         "APELLIDO": "CONDORI QUINTO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "vacio_",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1543561200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "EMy89d66cHM=",
//         "DNI": "00001021",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ROMA",
//         "APELLIDO": "TORRES",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1527145200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "4qdfK74PZ7A=",
//         "DNI": "00001841",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Zultán",
//         "APELLIDO": "Quispe Dávila",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1481958000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "LTw9ye2Q0nE=",
//         "DNI": "00001849",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Rex",
//         "APELLIDO": "Quispe Dávila",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Beige",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1481958000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "VQPetLKU9AI=",
//         "DNI": "00008045",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BEETHOVEN",
//         "APELLIDO": "REATEGUI SINARAHUA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@MOSTAZA",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "GOLDEN RETRIEVER",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1437202800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "4oI+vtJuEdw=",
//         "DNI": "00012469",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "romario",
//         "APELLIDO": "peña olivares",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Rottweiler",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1549522800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "oCXuOn/wyqg=",
//         "DNI": "00003345",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "rocco",
//         "APELLIDO": "pasco martinez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Rottweiler",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1491980400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "DP87X9XNaVY=",
//         "DNI": "69960729",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Simba",
//         "APELLIDO": "Olivares Alcarraz",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Shar Pei",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1564902000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "9179F4F4E8BCB422135D8618049C6813.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "EHI9difW7Jg=",
//         "DNI": "00012738",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Zeus",
//         "APELLIDO": "Guerrero Barraza",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Tricolor",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "sharpei con rottweiler",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1360911600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "jcg26bqbvTc=",
//         "DNI": "00007144",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ZEUS",
//         "APELLIDO": "RIVERA VILCACHAGUA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Atigrado",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Pitbull",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1536562800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "GV60w/y/xgs=",
//         "DNI": "00002886",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Rocco",
//         "APELLIDO": "Sanchez Oliva",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Pitbull",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1527836400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "0",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "gWs0UBfUfNU=",
//         "DNI": "40261581",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SCAM",
//         "APELLIDO": "CAPCHA FELIX",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Sal y pimienta",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Schanauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1478415600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "97AF7C9D8A8BB40503FD392B20B5A7FF.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "noi+GAfAR+I=",
//         "DNI": "00009600",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "patrick",
//         "APELLIDO": "malaga",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Sal y Pimienta",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Schanauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1507359600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "+IRI9GkxIjA=",
//         "DNI": "00002171",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Peluchin",
//         "APELLIDO": "Andrade Acuña",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cruce Pequines",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1407654000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "nveu239UQs4=",
//         "DNI": "00009093",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BEIMAX",
//         "APELLIDO": "CARPIO MENDOZA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Golden",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1530774000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "s6ph//Q7PIo=",
//         "DNI": "16558139",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Papi",
//         "APELLIDO": "Reyes Cardenas",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y negro ",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "unico",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1495695600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "318F8D4E13ABFAD28CADE6BA0243A64A.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "gFXbY+leimw=",
//         "DNI": "54241651",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Chester",
//         "APELLIDO": "Casazola Martinez ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y marrón bebé ",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cruce shih tzu pequi",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1619074800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "649429E501F2B66E2A3A62D84CCAF585.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "bXmGkJBmexM=",
//         "DNI": "00012596",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "snow",
//         "APELLIDO": "manrique silva",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Dorado",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Bichon Habanero",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1457766000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "m0AZBYAwsxc=",
//         "DNI": "00008883",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Oso",
//         "APELLIDO": "Bravo AsCENCIO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "BULLDOG",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1410850800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "x51uDOVno+A=",
//         "DNI": "00011042",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "rolly",
//         "APELLIDO": "trujillo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@blanco y mostaza",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "BULLDOG",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1543388400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "fyHdxHifFQI=",
//         "DNI": "00004579",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "RUSO",
//         "APELLIDO": "ESPINOZA GARCIA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO CON BLANCO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUCE CON PITBULL",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1532847600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "LyGWVpd/LL0=",
//         "DNI": "00009779",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "junior",
//         "APELLIDO": "otivo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cruce con cocker",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "JM3xydLqd0s=",
//         "DNI": "97988778",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Tony Stark",
//         "APELLIDO": "Valderrama Verde",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRIOLLO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1558594800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "F9E00DC9C33CE95E5A4645771B81BC1D.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Y36513N6U24=",
//         "DNI": "00008446",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "OSO",
//         "APELLIDO": "BATAL RODRIGUEZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@MOSTAZA",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU (CON PEQUIï",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1471244400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "lnyDj4cJwIg=",
//         "DNI": "95137934",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Negro",
//         "APELLIDO": "Cortez Tineo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Labrador con Rottweiler",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1433228400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "9A641C69CF5186982E8AC8C4E06E80F4.png",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Zh3pwO/1NMM=",
//         "DNI": "76828245",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Gordo",
//         "APELLIDO": "Apaza Loza",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Manto negro con manchas pardas",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cruce rottweiler",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1601017200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "2F3D6C65626472B77ED6D3A5AF41EA01.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "V1jC/9V7fiw=",
//         "DNI": "00003972",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "DRAKO",
//         "APELLIDO": "REGALADO MANRIQUE",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Oscuro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1512370800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "gVwg/iTvE30=",
//         "DNI": "00006584",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Gringo",
//         "APELLIDO": "PAREJA NALVARTE",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Hueso",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1343458800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "lNqX41AHkeQ=",
//         "DNI": "00010219",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "toby",
//         "APELLIDO": "Avia Solano",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1548831600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "cS25lclyJas=",
//         "DNI": "00001674",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Peluchin",
//         "APELLIDO": "Garcia Lucero",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1508050800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "kokKvooGvJY=",
//         "DNI": "00005670",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "KAPONES",
//         "APELLIDO": "MORVELI TORRES",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Marron",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1370588400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "8jPjeXG22V0=",
//         "DNI": "00004625",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "NEY",
//         "APELLIDO": "ORTIZ VALLEJO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1510383600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Ze6S+RmhZCY=",
//         "DNI": "00001016",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "TOFI",
//         "APELLIDO": "CHUQUIZUTA RUIZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Gris",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "PERUANO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1512802800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "BgrkF8rP50A=",
//         "DNI": "00010130",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SCOOBY",
//         "APELLIDO": "OCHOA VENTURA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@PLOMO Y NEGRO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Maltes",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1514271600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "TkwXVA+QaMU=",
//         "DNI": "00001032",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "PATRICIO",
//         "APELLIDO": "GALDOS CANDER",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "AMARILLO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "VIRINGO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1515567600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "mS2FvSpdhmA=",
//         "DNI": "00012620",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Rochy",
//         "APELLIDO": "Salvador ninive",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CHIHUAHUA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1558249200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "lYkpkWukkvI=",
//         "DNI": "00012842",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "French",
//         "APELLIDO": "De la cruz Olivares",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Chocolate",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CHIHUAHUA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1502953200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "T5z7oXECVnY=",
//         "DNI": "52180865",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Toby Danko",
//         "APELLIDO": "Jiménez Motta",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Crema",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CHIHUAHUA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1549522800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "DC554538FE6E748A32CB062BBF1E651A.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "fftEGSRMUdU=",
//         "DNI": "00006738",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "OTTO",
//         "APELLIDO": "JOYO GUTIERREZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CREMA CON CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CHIHUAHUA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1534575600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "gc2FnRor8Io=",
//         "DNI": "00003154",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "doky",
//         "APELLIDO": "medrano ovalle",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CHIHUAHUA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1418194800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "o0d1mnC2I2o=",
//         "DNI": "00003098",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "pisango",
//         "APELLIDO": "martinez carbajal",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1368169200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "yyKLg0YF6uc=",
//         "DNI": "00008072",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BayrÓn",
//         "APELLIDO": "velasco manihuari",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@gris y blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1522220400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "8cuDjDMFPMw=",
//         "DNI": "00008153",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "lucas",
//         "APELLIDO": "olazabal rojas",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Sal y Pimienta",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1529996400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "hnrL6ZY9xVI=",
//         "DNI": "00006827",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Draco",
//         "APELLIDO": "Porras ñahuis",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1518678000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "HtoivZ9Wv1U=",
//         "DNI": "00006652",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "NEGRITO",
//         "APELLIDO": "CUEVA RODRIGUEZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@NEGRO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1539586800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "2aa+hGmKNNg=",
//         "DNI": "00010240",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "lukas",
//         "APELLIDO": "arbaiza",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Sal y Pimienta",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1458457200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "NVL9X4Js4ik=",
//         "DNI": "00003587",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Docky",
//         "APELLIDO": "Callacna Calvo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Crema Aperlado",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1313564400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "wmrf7yWw33g=",
//         "DNI": "00010395",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "akiles",
//         "APELLIDO": "flores",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@atigrado con blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Pitbull",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1478588400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Qf+ez/9wW78=",
//         "DNI": "00002809",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Chester",
//         "APELLIDO": "Lizarraga Bailon",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chitzu",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1411628400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Gaa3qWR9Juw=",
//         "DNI": "00001421",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Bruno",
//         "APELLIDO": "Argomedo narro",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Gris",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "chitzu",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1516604400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "svDITu0L4Z0=",
//         "DNI": "00001318",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Rabito Thor",
//         "APELLIDO": "Vasquez Geronimo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Gris",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Maltes con Schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1336719600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "au5X/xhzCRI=",
//         "DNI": "00001319",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Rabito Thor",
//         "APELLIDO": "Vasquez Geronimo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Gris",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Maltes con Schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1336719600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "pTVmnfYWei4=",
//         "DNI": "00001320",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Rabito Thor",
//         "APELLIDO": "Vasquez Geronimo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Gris",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Maltes con Schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1336719600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "pdvNDtcn5+k=",
//         "DNI": "46282721",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Milo Alberto",
//         "APELLIDO": "moreno santibañez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Gris con dorado",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Yorkshire Terrier",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1536130800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "29BE58BA1ECA2CC46199362FF57DB093.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "gSR4u8lV+I4=",
//         "DNI": "00003232",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "brando",
//         "APELLIDO": "carbajal",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Maltez",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1496386800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "bJvLcE+MIZI=",
//         "DNI": "91693456",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Fido",
//         "APELLIDO": "Jimenez Hidalgo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marrón con blanco en el pecho y las patitas",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cruce Pastor Aleman",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1478415600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "27A663F58DCD2D88C8B6A05C472A18A2.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "yovQ+uF3Gz0=",
//         "DNI": "00009474",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "PIPO",
//         "APELLIDO": "GUIVAR LLACSAHUANGA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Pastor Australiano",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1451372400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "d8KJwKKem/g=",
//         "DNI": "00002359",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "kaleb",
//         "APELLIDO": "quispe ruiz",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Tricolor",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "bully standard",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "j/3opIcGrKc=",
//         "DNI": "58732836",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Comando",
//         "APELLIDO": "Jara Ccorahua",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "marron claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Chuskito",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1407481200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "45015FDF647BBA7D058BBDE9061CC6CD.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "PXMd45l6V2A=",
//         "DNI": "63219444",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Loki",
//         "APELLIDO": "Bermejo vargas",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marrón ",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Chuskito",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1362121200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "A34F7A182E69D69FDFDB65C44B99CA64.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "V6/wNWKP8Fw=",
//         "DNI": "33954330",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Shadow",
//         "APELLIDO": "Trujillo ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "PASTOR BELGA",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1562655600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "E958F912F1F1FEA31E254D8A9AF1825A.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "kmyYgECJs0U=",
//         "DNI": "84473979",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Thor",
//         "APELLIDO": "Villar Ticlavilca",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "NEGRO CON BLACO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "american bully/pitbu",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1527231600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "9BAD02571A0355E4A035C155DB391FCC.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "L3pz6i6q0Kg=",
//         "DNI": "00003512",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "leyko",
//         "APELLIDO": "guerrero aguirre",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "vacio_",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "pitbull americano",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1499756400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Gq/GVYkAUUU=",
//         "DNI": "00009881",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ARCANO",
//         "APELLIDO": "CASTRO PEREDA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@BLANCO CON CREMA",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Pittbul",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1515740400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "dGoI0aLNjrI=",
//         "DNI": "00003632",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "machi",
//         "APELLIDO": "vidalgo francisco",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "terrier escoces",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1450162800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "gK9Qls7PKuE=",
//         "DNI": "00002208",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Goku",
//         "APELLIDO": "Flores Chavez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1377414000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "zB5l7zckdSM=",
//         "DNI": "00002443",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SHESTER",
//         "APELLIDO": "VELA MONTALVO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Sal y Pimienta",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1483599600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Z6qRohO3pr8=",
//         "DNI": "00008760",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "gio",
//         "APELLIDO": "rodriguez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Sal y Pimienta",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1473750000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "bFPKThsqMhg=",
//         "DNI": "00009025",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BEILLIE",
//         "APELLIDO": "PAZ CARRIÓN",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Gris",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1487833200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "4otDVygriCc=",
//         "DNI": "00009057",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "chester",
//         "APELLIDO": "COLONIO ROMíN",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Gris",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1483254000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Oz5lqZZF6gg=",
//         "DNI": "00004755",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "GATO",
//         "APELLIDO": "REVILLA VILLACORTA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Sal y Pimienta",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1486882800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "18ZWz9vTr2c=",
//         "DNI": "00004953",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SAMMU",
//         "APELLIDO": "MONTERO PACIFICO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@SAL Y PIMIENTO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cocker con schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1530342000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "NrWLls0BOBc=",
//         "DNI": "00002793",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "chiquito",
//         "APELLIDO": "flores castromonte",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Pekines",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1436166000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "L1kiPivRSjU=",
//         "DNI": "00007366",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "CARAMELO",
//         "APELLIDO": "MUÑOZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Pekines",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1500274800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "pEMN1ix2ePA=",
//         "DNI": "00008296",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "KIRIKOU",
//         "APELLIDO": "MELGAREJO CABRERA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Meztizo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1251615600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "odA1y1+L6kQ=",
//         "DNI": "10271401",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Vodky Gatuno ",
//         "APELLIDO": "Sanchez Ulco",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron y blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1572418800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "0B6E39D73BF91651AD76349E38067C67.JPG",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "BLtigcOd1yA=",
//         "DNI": "81412012",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Neron",
//         "APELLIDO": "Solis villar",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1495263600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "3B85F0A9401A400F160C1C293FE2959E.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "eGOz8lJ63Kc=",
//         "DNI": "80379449",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Rambo",
//         "APELLIDO": "Rios Suárez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Monocromático, leonado",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1414825200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "F0815B5EC86F7CCE2EA230A1DCC2FE93.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "9Zphx32xwu0=",
//         "DNI": "67388165",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Petizo",
//         "APELLIDO": "Alvarez Calderon",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1460617200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "00EDCA0EAC577434EBE1B76715E828EC.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "14ALn66bO3w=",
//         "DNI": "70107964",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Pinky (Chuqui)",
//         "APELLIDO": "Huayanay Risco ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Caramelo ",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1523170800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "459A952DC77E8D9757246964DEE7FD18.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "5Fgr4mWe/FE=",
//         "DNI": "00001650",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Tyson",
//         "APELLIDO": "Ciriaco Fernandez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Gris y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "American Bully",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1536217200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "8PnqGbrbvFQ=",
//         "DNI": "00006366",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "MOTOMOTO",
//         "APELLIDO": "DUEñAS",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@PLOMO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "American Bully",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1504076400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "cfBDiDFj27o=",
//         "DNI": "00006697",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ROCCO",
//         "APELLIDO": "TORRES CASTRO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@BLUE",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "American Bully",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1453618800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "7t3mn03dh64=",
//         "DNI": "14195185",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Anteojos",
//         "APELLIDO": "Huaynates Quincho",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco con manchas de color mostaza",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Jack Russel Terrier",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1663657200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "2F45BAF9751EDA5C7B499C531CB91EF0.PNG",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "NQK9Mr4C3QA=",
//         "DNI": "00003777",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "jack",
//         "APELLIDO": "porras casaretto",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Marron",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cruzado dogo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1384758000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "SfKS21Vc5rU=",
//         "DNI": "00003846",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Layfu",
//         "APELLIDO": "Castro Josan",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "schnauzer Chileno",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1260342000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "w0xTvedQ0WU=",
//         "DNI": "00003888",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "spooky baloo",
//         "APELLIDO": "mendoza vega",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Marron",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "jack russell terries",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1435906800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "xlcO3t7JmR4=",
//         "DNI": "00003966",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "bronco",
//         "APELLIDO": "ruiz ribera",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cuto",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1253775600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "L9FFxkWFDlw=",
//         "DNI": "87673766",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Shadow",
//         "APELLIDO": "Montalico Curo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Gris",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1398927600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "B59FCF9B84915E8196BB2BA4EA8BE54A.PNG",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "r7CrWxoF4ck=",
//         "DNI": "16184487",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Chat Jose Alfredo Johnson Junnior",
//         "APELLIDO": "Cruzado Marroquín ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Sal y pimienta ",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1493967600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "ACCBFD0EF1051B082DC4AE223CF07DA7.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "rAR1qY6VxIw=",
//         "DNI": "00007435",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "hercules",
//         "APELLIDO": "sucno enciso",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "mini pinscher",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1434351600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "BaKbgLcxb4E=",
//         "DNI": "00009189",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Hatchi Guillermo",
//         "APELLIDO": "Grados sínchez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Tricolor",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "shih tzu/schnauzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1525158000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "eSNLhCfFLws=",
//         "DNI": "00008217",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ragnar",
//         "APELLIDO": "muñoz salazar",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@atigrado",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "pitbull stanford",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1526367600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "c7rZ73ieR/U=",
//         "DNI": "00008742",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ROCCO",
//         "APELLIDO": "COLDERO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CRUZADO CON LOBO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Qaacib6fMaU=",
//         "DNI": "68933415",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Fido",
//         "APELLIDO": "Sierra Sinchitullo ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marrón claro ",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Golden Retriever",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1460444400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "34A5E9DEE9A7EC1A532CD06B782F40BF.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "RTyo01FvUDQ=",
//         "DNI": "90920759",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Brando",
//         "APELLIDO": "Mamani Telleria",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Dorado oscuro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Golden Retriever",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1520233200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "95654404C18F0EB81F7BF9771B33BAA6.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "AxzgYh3cAE0=",
//         "DNI": "62082264",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Laykan ",
//         "APELLIDO": "Vela Paucara ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marrón",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "CHITZU CRUZADO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1542956400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "5A628FA66251CCACE84659B5A1128F97.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "N/z+mZHavnw=",
//         "DNI": "03552975",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "DRAKO THANOS",
//         "APELLIDO": "CRISTOBAL ENGRACIO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "ATIGRADO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "pitbull/mestiza",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1567666800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "ECA061AEF4D005129332EEFBFEB75680.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "DL5DPSFa240=",
//         "DNI": "00002409",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ñañau",
//         "APELLIDO": "adsfasd",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "OTRO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1545548400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "I/Huoiz8RGs=",
//         "DNI": "00002721",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "oliver",
//         "APELLIDO": "gavidia corrales",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cocker cruzado",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1532761200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "MIIwcU5+JPQ=",
//         "DNI": "90552939",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Hashy",
//         "APELLIDO": "Diaz Cavagnaro Diaz",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Hueso",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "labrador",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1282892400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "108FE115000C147427DA50C2B0C9CE31.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "qQ0xU3b6n8w=",
//         "DNI": "00005638",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "kiwy",
//         "APELLIDO": "espinoza",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "yorshire",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1539673200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "i3fCKOsyaKI=",
//         "DNI": "82050913",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Yogui",
//         "APELLIDO": "Llallico",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cruzado mestizo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1318230000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "F60DE1075556B3D7ECE2E50ED333B022.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Zh4la1DxpX8=",
//         "DNI": "00004217",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "CONE",
//         "APELLIDO": "VICENTE ENRIQUEZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@MIEL",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "COCKER CON SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1343113200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "/XvgRJrjFdA=",
//         "DNI": "17937515",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Lucas Alejandro",
//         "APELLIDO": "Martínez Oscurima",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "ROTWEILER",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1530082800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "F88920D4589DAE7ABEC2EB05FCA153E3.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "iXbIqoEQEow=",
//         "DNI": "28180767",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Mustafa",
//         "APELLIDO": "Pio Amaya",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "labrador mestizo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1448002800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "36F9C2AE6EC1D54417FA9C6BA46BA9BD.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "uiTZIbSBdfo=",
//         "DNI": "42405128",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "LOCKY",
//         "APELLIDO": "VASQUEZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "NEGRO CON MOSTAZA",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Mestizo con Pastor Alemán",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1385362800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "o79KdNgZe00=",
//         "DNI": "00011985",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BOOMER",
//         "APELLIDO": "CRESPO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "PEQUINí‰S/SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1419145200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "gZHSPmsurps=",
//         "DNI": "14518946",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "COCO",
//         "APELLIDO": "PACHAS ANDRADE",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1447311600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "FBFE09EF251AD45DC509C7B26134E0F3.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "12cV2tKaqxQ=",
//         "DNI": "00008165",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SCRAPI-DOO",
//         "APELLIDO": "SUAREZ DURAND",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1378191600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Wndm6V1RjcQ=",
//         "DNI": "00007247",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "doky",
//         "APELLIDO": "ascencio chura",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1505890800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "J/bWnmMVpFM=",
//         "DNI": "00003100",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "edu",
//         "APELLIDO": "martinez carbajal",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@blanco con caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1300777200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "UtyxDcx0tac=",
//         "DNI": "00003190",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Loky",
//         "APELLIDO": "Vilcapama Balcazar",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@caramelo con negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1529564400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "+TWKmEAjDSs=",
//         "DNI": "00003220",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "tyson",
//         "APELLIDO": "ordinola balarezo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1470985200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "AntAEg8YAJw=",
//         "DNI": "00008437",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "CHESTER",
//         "APELLIDO": "BATAL RODRIGUEZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1487487600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "euxHLkGnpb4=",
//         "DNI": "00009109",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "TAYSSON",
//         "APELLIDO": "Cueva Flores",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CREMA",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1387263600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "xvzPY2MS/z4=",
//         "DNI": "00009489",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Peluchín",
//         "APELLIDO": "Pardo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1416553200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "2GSKn8LOmMc=",
//         "DNI": "00009594",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "DRAKO",
//         "APELLIDO": "SANCHEZ DEL POZO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1460530800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "K8+XzaIpByU=",
//         "DNI": "00012239",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Ignis",
//         "APELLIDO": "Carpio Parra",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1516172400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "elR0K8eBqxo=",
//         "DNI": "00005786",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BETHOVEN",
//         "APELLIDO": "LEZCA ZEVALLOS",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Tricolor",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1353481200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "9X132ZqsgK8=",
//         "DNI": "00001089",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Coco",
//         "APELLIDO": "Becerra",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "06kdhjFHGSU=",
//         "DNI": "00010175",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "HONUR",
//         "APELLIDO": "CHUMPITAZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1423033200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "U+lTnSrjgPk=",
//         "DNI": "00002894",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Chester",
//         "APELLIDO": "Romero",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Crema",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1292050800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "mLxLK7qoxEA=",
//         "DNI": "00003072",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "doki",
//         "APELLIDO": "alvarez quintos",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Sal y Pimienta",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1296543600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "IrotVFclHUo=",
//         "DNI": "00004277",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Chester",
//         "APELLIDO": "Mena de paz",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Beige",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1524466800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "OcqU2YnPhlM=",
//         "DNI": "00004291",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "max",
//         "APELLIDO": "llanos malqui",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "vacio_",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1387954800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "VJ2Rg6vbN/0=",
//         "DNI": "00006306",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "TORO TORIBIO",
//         "APELLIDO": "PAREDES PORRAS",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@NEGRO CON RUBIO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1385794800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "1sxbjYmXIrs=",
//         "DNI": "00006396",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Tobby",
//         "APELLIDO": "Levano Torres",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Mostaza",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1484636400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "CPHWCEGgm2I=",
//         "DNI": "00003629",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "bambi",
//         "APELLIDO": "ponse alfaro",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1473922800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "nIF3oqBbF/Q=",
//         "DNI": "00003729",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Otto",
//         "APELLIDO": "Castrillon Revilla",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Blanco y gris",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1482562800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "wOYuDP9Ci6c=",
//         "DNI": "00004127",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "CHUVY",
//         "APELLIDO": "VELA PALACIOS",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1431500400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "fi0BB0Zc+hc=",
//         "DNI": "00010384",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "toffy",
//         "APELLIDO": "cornejo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@beige/crema",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1476514800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "vsifAXX6s34=",
//         "DNI": "00010420",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "machin",
//         "APELLIDO": "machuca",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1533538800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Dqu/x8hMqTQ=",
//         "DNI": "00010730",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "TUFITO",
//         "APELLIDO": "ANGERAMI CABANILLAS",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "SHIH TZU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1409209200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "BrHpAqLPRVY=",
//         "DNI": "38898340",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Lucky",
//         "APELLIDO": "Arista",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marrón con crema",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Shihtzu",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1677826800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "325AC49CD202791337994FBEC4DB315A.png",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "q3FAXSA/E5k=",
//         "DNI": "00003020",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "HACHIKO",
//         "APELLIDO": "CANO PEREZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1281250800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "obub6B9W5EU=",
//         "DNI": "00003023",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "TOTO",
//         "APELLIDO": "CANO PEREZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1500102000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "bXvzdaz5wDQ=",
//         "DNI": "00003292",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Messi",
//         "APELLIDO": "Saavedra Danian",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Blanco con caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "IEcvv0vvybI=",
//         "DNI": "00007101",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ROCKY",
//         "APELLIDO": "DEL AGUILA PINEDO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(977295600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Ym7LqPK6l8s=",
//         "DNI": "00007200",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SANTIAGO",
//         "APELLIDO": "PUMACALLE CASTILLO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1356850800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "PPri2ehKdJo=",
//         "DNI": "00007297",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "HACHI",
//         "APELLIDO": "TORRES SIMON",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@MOSTAZA",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1529046000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "EKBqMSvNYrE=",
//         "DNI": "00007306",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ROCKY",
//         "APELLIDO": "Fernandez IPARRAGUIRRE",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@MARRON CON BLNACO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1446188400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "6hMAESdnVLQ=",
//         "DNI": "00002366",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "rocky",
//         "APELLIDO": "zavala cordova",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1488870000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "0PHP+6hC4gc=",
//         "DNI": "00002805",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Gruff",
//         "APELLIDO": "Navarrete Rios",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Marron y blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1532070000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "EhtHqIM+f0w=",
//         "DNI": "00002956",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "fito",
//         "APELLIDO": "sanchez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "vacio_",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1150441200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "2CO/slk2ohI=",
//         "DNI": "00007403",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "OSO",
//         "APELLIDO": "VASQUEZ PANTA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Marron",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1412492400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "gbYVM5QPlpE=",
//         "DNI": "00007705",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "bobby",
//         "APELLIDO": "corales montenegro",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1388732400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "2OimvNJGyXM=",
//         "DNI": "00007871",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SCOTT",
//         "APELLIDO": "LIVIAPOMA SOLANO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Marron",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1419058800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "/NXVjNUL/dQ=",
//         "DNI": "00007896",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "angus",
//         "APELLIDO": "vega peña",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@marron oscuro y neg",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1420527600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "lkSG6vLXjRo=",
//         "DNI": "00007936",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Fido",
//         "APELLIDO": "Santillan Lagos",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1532329200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "boWtCMJy8Fc=",
//         "DNI": "00007962",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "KERIN",
//         "APELLIDO": "LOYOLA ARGOTE",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1434351600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "rYSAQheXf9s=",
//         "DNI": "00008138",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Odie Federico",
//         "APELLIDO": "RamÍrez AntÓn",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@caramelo y negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1463382000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "vD69iS3Bw78=",
//         "DNI": "00008156",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Tobias Joaquin",
//         "APELLIDO": "crisostomo cahuana",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1432882800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "IVRBUdMsjpg=",
//         "DNI": "00008159",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "gringo",
//         "APELLIDO": "paredes prieto",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@crema",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1430463600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "JmglPCo/9ng=",
//         "DNI": "00008186",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "doggy",
//         "APELLIDO": "quispe sandoval",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1432450800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "gfWAg8U7Bic=",
//         "DNI": "00008228",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BEETHOVEN",
//         "APELLIDO": "ESPINOZA DE LA CRUZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1488265200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "qrN2hO2VE6Q=",
//         "DNI": "00008277",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ENANO",
//         "APELLIDO": "CHOQUEZ ALFONSO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1525330800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "4OprXKk97K0=",
//         "DNI": "00008291",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "VALENTINO",
//         "APELLIDO": "SIFUENTES SíNCHEZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@NARANJA CON BLANCO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1313046000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "6z8Uw8kpz5Y=",
//         "DNI": "00003713",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Gringo",
//         "APELLIDO": "Cardenas",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1420441200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "RS5oOVpBb0c=",
//         "DNI": "00003787",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "rocky",
//         "APELLIDO": "lopez cotera",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Beige",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1281596400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "E3evzYBjb8E=",
//         "DNI": "00004054",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "kaiser",
//         "APELLIDO": "ayala rebolledo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(955782000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "LAMZXYcj4fk=",
//         "DNI": "00004242",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BOBY",
//         "APELLIDO": "PEDROSO AGUIRRE",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1328079600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "puSpENkjQLo=",
//         "DNI": "00006058",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "thor",
//         "APELLIDO": "tafur victorio",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "vacio_",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1513753200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "OXHpsJEHCyg=",
//         "DNI": "00006600",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "DRAGO",
//         "APELLIDO": "MAS RAMIREZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "FH9JmSMXTPY=",
//         "DNI": "00006734",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "ÑOÑO",
//         "APELLIDO": "TELLO CHAVEZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1474009200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "JrH7KcH11v0=",
//         "DNI": "00006737",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "mans",
//         "APELLIDO": "quispe pareja",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1407826800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "s13M29PGk2M=",
//         "DNI": "00010537",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "KAISER",
//         "APELLIDO": "CASTILLO VENTURA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@BL. CON CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1265007600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "iGxwvP5hKCs=",
//         "DNI": "00010539",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SCOTT",
//         "APELLIDO": "OLIVERA GARFIAS",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1506841200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "S2waYw1hGqc=",
//         "DNI": "00010678",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "NEGRO",
//         "APELLIDO": "HUARANGA ALMANZA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1509606000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "SeJHBkMLdbs=",
//         "DNI": "00010702",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "manchas",
//         "APELLIDO": "nuñez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@blanco con negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1526281200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "s4mZc2bfp0c=",
//         "DNI": "00010758",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Buk",
//         "APELLIDO": "Gonzales Sanchez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1441954800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "AzFOE9kSsvI=",
//         "DNI": "00010895",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Reyser",
//         "APELLIDO": "Mozombite Velasque",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1488956400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "/xkpKs5wNj8=",
//         "DNI": "00010936",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Hercules",
//         "APELLIDO": "Infante Ayala",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1451631600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "aFsA9RpsQhs=",
//         "DNI": "00007052",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "GRINGO",
//         "APELLIDO": "GUEVARA TIMANA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1513494000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "qksIP1uKbc4=",
//         "DNI": "00009605",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "CHESTER",
//         "APELLIDO": "SARMIENTO CESPEDES",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CREMA CON BLANCO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(-62135571600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "V1/2fpFnpMk=",
//         "DNI": "00009867",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BOS",
//         "APELLIDO": "PAZ SULLÓN",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1545807600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "qDDr8r+/PWQ=",
//         "DNI": "00009936",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "DRAKO",
//         "APELLIDO": "FELIX EVANGELISTA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@NEGRO CON AMARILLO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1523775600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "ZB7/hRwWcxc=",
//         "DNI": "00010319",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "TOBI",
//         "APELLIDO": "CONO BARBOSA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1442905200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "jreSPnCq03g=",
//         "DNI": "00001802",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Tomas",
//         "APELLIDO": "Ubillus Galindo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1054450800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "rCzfuDe8S6M=",
//         "DNI": "00004678",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "chapo",
//         "APELLIDO": "HUACACHI BUSTAMANTE",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1516258800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "8PlpaM7zyGs=",
//         "DNI": "00004709",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BOBY",
//         "APELLIDO": "PETTERMAN RUBIO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1317538800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "xcN94OabUgg=",
//         "DNI": "00004716",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Boo",
//         "APELLIDO": "Onys Frost",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Mostaza, blanco y n",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1440313200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "5DU8A6vUgw0=",
//         "DNI": "00005103",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "MANCHAS",
//         "APELLIDO": "SANDOVAL SANCHEZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1491894000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "F7b8/0EgLvs=",
//         "DNI": "00005422",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SNOW",
//         "APELLIDO": "PALOMINO RICALDE",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1496905200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "gyZnZUyy/WI=",
//         "DNI": "00005523",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "oso",
//         "APELLIDO": "laurante cuaresma",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Claro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1096614000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "5tpCCELOrnI=",
//         "DNI": "00005694",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "KIUBI",
//         "APELLIDO": "ISIQUE MONTENEGRO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1463814000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "71yEn12uRvU=",
//         "DNI": "00008354",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "pantera",
//         "APELLIDO": "nolasco puquio",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1254380400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "6ps5x6myAOs=",
//         "DNI": "00008403",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "LASI",
//         "APELLIDO": "ZELAYA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1466060400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "YNnfauehKVQ=",
//         "DNI": "00008442",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "PRECIOSO",
//         "APELLIDO": "ROMANI PORTILLO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1475996400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "sy6PhoRxxcQ=",
//         "DNI": "00008605",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "NEGRO LUCHO",
//         "APELLIDO": "JUAREZ OLIVERA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1401951600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "6XkH312mYZA=",
//         "DNI": "00009148",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "rabo",
//         "APELLIDO": "paredes meza",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1473577200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "8p3cF9LsOgM=",
//         "DNI": "00009231",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "TOBY",
//         "APELLIDO": "CUEVA AZURIN",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@BLANCO NEGRO Y MARR",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1351666800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "pZ7XSPiFkDQ=",
//         "DNI": "00009331",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BRONCO",
//         "APELLIDO": "ASENJO FLORES",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CREMA",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1425106800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "ad2HUNgd7RI=",
//         "DNI": "85606602",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Habibi",
//         "APELLIDO": "Ramos Zurita",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1594796400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "15CC8B2F826F698033D18DE14873B275.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "/K2Fyzuz3z0=",
//         "DNI": "00008304",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "OTTO",
//         "APELLIDO": "JIMENEZ NUñEZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1299481200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "vTSzj4owdcM=",
//         "DNI": "00011157",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "CHESTER",
//         "APELLIDO": "CHICOMA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CHOCOLATE",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1345618800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "z7U8DB14DHU=",
//         "DNI": "00011522",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "MIcHEL",
//         "APELLIDO": "CARRASCO FLORES",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(946710000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "vSlCUE5yFBY=",
//         "DNI": "00011531",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "PANCHO",
//         "APELLIDO": "VASQUEZ SAENZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@BLANCO CON MARRON O",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1508310000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "I2eCEGdeNXI=",
//         "DNI": "00011578",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "OSO",
//         "APELLIDO": "ESPINOZA CCOTO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marron Oscuro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1522911600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "UbfRNMnqj6s=",
//         "DNI": "00011317",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "bronco",
//         "APELLIDO": "bocanegra meza",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Marron",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1521874800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "IYB6ebShTek=",
//         "DNI": "00011521",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "YACO",
//         "APELLIDO": "CADENAS CARBAJAL",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@MARRON",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "PEQUINES",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1531206000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "k2sySUve2EU=",
//         "DNI": "00009035",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "scoot",
//         "APELLIDO": "vasquez chacchi",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "PEQUINES",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1519714800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "X+/nu0nzaBg=",
//         "DNI": "00008344",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "cutin",
//         "APELLIDO": "puquio rodriguez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@caramelo y negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "PEQUINES",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1409554800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "0",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "9U/ASZqpyWU=",
//         "DNI": "42437246",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Toby Pitufo",
//         "APELLIDO": " falcón Vílchez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Beige",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Pequinés",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1350716400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "2FBBC583D9DCDE9FD4EB022E25F3FA37.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "C5RW1z62JbY=",
//         "DNI": "00012412",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "prÍnCIPE PELUCHIN",
//         "APELLIDO": "ALBINES DE LA CRUZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "poodle",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1524812400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "WIsjxchzyZM=",
//         "DNI": "00012744",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Paco",
//         "APELLIDO": "Enciso salgado",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "poodle",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1280991600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "tdmN4Qo+Nzs=",
//         "DNI": "00008162",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "bimbo",
//         "APELLIDO": "guerrero ancaya",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "poodle",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1536303600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "/E1WVwnm4Mg=",
//         "DNI": "00003006",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BOBBY",
//         "APELLIDO": "COSQUILLO CARBONERO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CHAMPAGNE",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "poodle",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1507186800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "23Mm6L1ZNZQ=",
//         "DNI": "00007351",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Toma",
//         "APELLIDO": "Bustamante Laymito",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Amarillo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "poodle",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1523602800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Di+rHTO08zA=",
//         "DNI": "00008152",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "TACHALA",
//         "APELLIDO": "ROMERO RUIZ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1519110000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Kv72rWkgyeY=",
//         "DNI": "00008084",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "MANCHAS",
//         "APELLIDO": "COLLANTES LIñAN",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "vacio_",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(3113362800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "YhFr9KICWv8=",
//         "DNI": "00003467",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Toby",
//         "APELLIDO": "Paredes Moya",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1447830000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "DABH1/vm1ZU=",
//         "DNI": "00005732",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BRAVO",
//         "APELLIDO": "GONZALEZ CUBAS",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO CON NEGRO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1452927600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Kc+F4jOqI80=",
//         "DNI": "00001155",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Jack",
//         "APELLIDO": "Araujo",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1416553200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "lmfGtPURTVY=",
//         "DNI": "00001365",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Chencho",
//         "APELLIDO": "Salas",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1399359600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "6vB39LBHeMw=",
//         "DNI": "00009948",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "copito",
//         "APELLIDO": "zapata",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@beige y blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1520406000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "aBurbqR5WK8=",
//         "DNI": "00006847",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Caramelo",
//         "APELLIDO": "Ventura Bonifacio",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Caramelo con Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1483081200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "AUkIIXbFOy4=",
//         "DNI": "00006871",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Lucky",
//         "APELLIDO": "Diaz Ordinola",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1475650800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "YGnc4S5euEE=",
//         "DNI": "00008824",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "sam",
//         "APELLIDO": "cerron",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1436943600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "osW50jZcHXY=",
//         "DNI": "00008656",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "LUCAS",
//         "APELLIDO": "RUIZ TAPUI",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1534489200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "B7G34WeXjII=",
//         "DNI": "00011306",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "rufo",
//         "APELLIDO": "ruiz",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@blanco con caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1507446000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "iGDudNqr3Pg=",
//         "DNI": "44842068",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Rupa",
//         "APELLIDO": "Cervantes Reyna ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "No tiene pelaje, piel color cenizo casi negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Perro peruano",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1223622000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "8C25826A98BE6311DB30DE16254CC578.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "2UloCag67Ro=",
//         "DNI": "00003487",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Pele",
//         "APELLIDO": "Chicome Morales",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Perro peruano",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1452495600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "9HS6n4ZslVs=",
//         "DNI": "00003820",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "small",
//         "APELLIDO": "patilla torres",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "siberiano",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1443596400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "+NTIMHrPPPI=",
//         "DNI": "00007975",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "RUFFO",
//         "APELLIDO": "MORI MOROCHO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@MOSTAZA",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "siberiano",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1431241200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "NyI7BygHrEc=",
//         "DNI": "00010389",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "tohru",
//         "APELLIDO": "quispe",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "siberiano",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1407913200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "HteugawcOO8=",
//         "DNI": "17627624",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "dsa",
//         "APELLIDO": "Chara Pelaez",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Poodle caniche",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1571209200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "80E4E458131F4EDC49CACAD3EC586437.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "QGXp4zDu3AY=",
//         "DNI": "11653814",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Teddy",
//         "APELLIDO": "Sebastian Blas",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Poodle caniche",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1703746800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "7C402DD30C1ED06763D66676B310F7F2.PNG",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "1RKyDoLT2iI=",
//         "DNI": "00227021",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Nerito",
//         "APELLIDO": " Ñaupari ",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cruce de labrador",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1510902000000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "D4C1F2964FB52FA60640E280F005AFA7.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "rEi6dULBhAs=",
//         "DNI": "58746704",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Salim ",
//         "APELLIDO": "Burga Valderrama",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Chusco",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1536130800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "111DF71156B0F9BF5DF48FB11C3C4647.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "08DCy5xA4bc=",
//         "DNI": "09170984",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Papacho",
//         "APELLIDO": "Gamboa Montufar",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marrón ",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Chusco",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1450076400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "B47E73A01DE2EDA1044F574A05F89DAA.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "Oq5mO5k5Zjk=",
//         "DNI": "00010975",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "gringo",
//         "APELLIDO": "mayer de pinto",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@crema",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Chusco",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1379228400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "+hRRs3kKhZk=",
//         "DNI": "00006214",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SCRAPY",
//         "APELLIDO": "CERON PAULINO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Negro y Marron",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Chusco",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1412146800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "DNQbWHmm8hA=",
//         "DNI": "00002537",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "SPARKY BRONCO",
//         "APELLIDO": "ROMERO LAURA",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@Beige Claro y oscur",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR RETRIVE",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1505890800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Chico",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "YikOTll1baU=",
//         "DNI": "00002652",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "bandy",
//         "APELLIDO": "ayala rivera",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "frass poodle",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1255071600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "SFFm5hBvqK8=",
//         "DNI": "00004842",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "symon",
//         "APELLIDO": "salazar gamarra",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Sal y Pimienta",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "schnawzer",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1366441200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "i9tTfsOKcJE=",
//         "DNI": "61336801",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Danko",
//         "APELLIDO": "Davila Vivanco",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "CARAMELO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cruzado con labrador",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1371106800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "B28056072EE588A30755FD0CBDC821C0.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "eszmt+iuqmc=",
//         "DNI": "00003316",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Branco",
//         "APELLIDO": "Jimenez Arias",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@marron con negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Cimarron",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1512543600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "dMuVRyDWrOQ=",
//         "DNI": "00003469",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "brandon junior",
//         "APELLIDO": "valentin vega",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "pitbull criollo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1426834800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "fkz32Qu0DFQ=",
//         "DNI": "04961078",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "OREJAS",
//         "APELLIDO": "Lucana Zegarra",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Marrón con blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cruzado con beagle",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1330412400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "59E29DC7066B5A6FCF68B48E6DD67347.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "0DsMT+MgotI=",
//         "DNI": "00010535",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "KALU",
//         "APELLIDO": "MEJIA ROMERO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@CARAMELO CON BLANCO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "American Staffordshire Terrier ",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1478156400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "c7EeRCzsmZ4=",
//         "DNI": "00006470",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "MANCHAS",
//         "APELLIDO": "PRADO ROSALES",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco y Negro",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "MESTIZO DE PEKINES",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1438153200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "w8xjzn+xaMs=",
//         "DNI": "00008287",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "BALTO",
//         "APELLIDO": "JIMENEZ CASTILLO",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "@BLANCO CON ATIGRADO",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "PAMPA ARGENTINO (CRU",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1508914800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "meUYwci8v5U=",
//         "DNI": "00008764",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "simba",
//         "APELLIDO": "santiago apari",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Beige",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "cruzado con chow cho",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1541746800000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "nh/iVYhLz6E=",
//         "DNI": "85615439",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Kaisser Paolo",
//         "APELLIDO": "Huaita Mora",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Caramelo con blanco",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "LABRADOR CON MESTIZO",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1506236400000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "B7AAA86C38D234CC610036399A71F08A.jpg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Grande",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "cVur4s2uW2o=",
//         "DNI": "67377676",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Pipo",
//         "APELLIDO": "Falcon",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Blanco con gris ",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "OVEJERO INGLES",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1363503600000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "78F2B989FB33152A62F93A825CF275DD.jpeg",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     },
//     {
//         "__type": "ENTIDAD.EMascota",
//         "TAMANO": "Mediano",
//         "TIPO": null,
//         "RAZA": null,
//         "CALIFICACION": null,
//         "SEXO": "Macho",
//         "CORREO": null,
//         "OBSERVACION": null,
//         "TELEFONO": null,
//         "CELULAR": null,
//         "ID": 0,
//         "ID_ENCRIP": "IbAcaVhFH1o=",
//         "DNI": "14684654",
//         "USUARIO_ID": 0,
//         "USUARIO": 0,
//         "NOMBRE": "Milo José",
//         "APELLIDO": "Benítez Figuera",
//         "COD_MICROCHIP": null,
//         "ESPECIE": null,
//         "MASCOTA_TIPO_ID": 0,
//         "MASCOTA_RAZA_ID": 0,
//         "COLOR": "Caramelo",
//         "TIPO_DSC": "CANINO",
//         "RAZA_DSC": "Goldendoodle Mestizo",
//         "BIOGRAFIA": null,
//         "FEC_NAC": "/Date(1692601200000)/",
//         "FEC_CREA": null,
//         "FEC_EMI": null,
//         "FEC_CAD": null,
//         "FAMILIARP": null,
//         "DNIP": null,
//         "TELEFONOP": null,
//         "FAMILIARM": null,
//         "DNIM": null,
//         "TELEFONOM": null,
//         "GEOGRAFIA_ID": 0,
//         "DEPARTAMENTO": null,
//         "PROVINCIA": null,
//         "DISTRITO": null,
//         "DIRECCION": null,
//         "PISO": null,
//         "REFERENCIA": null,
//         "GALERIA_ID": 0,
//         "FOTO": "78F1B22336457F75EC39869D421BC56F.png",
//         "EXTENSION": null,
//         "CASTRADO": 0,
//         "VISITA": 0,
//         "ALERGIA_MEDICAMENTO": 0,
//         "VACUNACION": 0,
//         "ANTIRRABICA": 0,
//         "FEC_ANTIRRABICA": null,
//         "ALERGIA": 0,
//         "ALERGIA_DSC": null,
//         "ENFERMEDAD": 0,
//         "ENFERMEDAD_DSC": null,
//         "FEC_DESPARACITACION": null,
//         "SEXTUPLE": 0,
//         "FEC_QUINTUPLE": null,
//         "QUINTUPLE": 0,
//         "FEC_SEXTUPLE": null,
//         "TRIPLEFEL": 0,
//         "FEC_TRIPLEFEL": null,
//         "LEUCEMIA": 0,
//         "FEC_LEUCEMIA": null,
//         "LIMP_DENTAL": 0,
//         "OPCION": 0,
//         "ESTADO": 0,
//         "lMASCOTA": null,
//         "NOMBRE_PRE": null,
//         "APELLIDO_PRE": null,
//         "PASSWORD": null,
//         "MES": 0,
//         "INDICE": 0,
//         "FEC_INI": null,
//         "FEC_FIN": null
//     }
// ]




export const results=
   
        [
            {
                "__type": "ENTIDAD.EMascota",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "nToNWJIrvys=",
                "DNI": "59005191",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Kali Charlote",
                "APELLIDO": "Aguirre Gómez",
                "SEXO": "Hembra",
                "COD_MICROCHIP": null,
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "CALIFICACION": null,
                "COLOR": "#ECB176",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1626246000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "/dog.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "a3aVOLIEvY4=",
                "DNI": "00007629",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "CONNIE",
                "APELLIDO": "ANDIA SOTOMAYOR",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "#FFF2E1",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1523084400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "/pauline-loroy-U3aF7hgUSrk-unsplash.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "RccRNEmwqlc=",
                "DNI": "00002103",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Oso",
                "APELLIDO": "Vargas Miscua",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "#fff",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1295506800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "/justin-veenema-NH1d0xX6Ldk-unsplash.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "lLZ39CdF/R4=",
                "DNI": "00010543",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "OSO",
                "APELLIDO": "JIMENEZ VELASQUEZ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "#000",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1528095600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "/david-lezcano-m-Doa-GTrUw-unsplash.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "HoiFJ3AWD3s=",
                "DNI": "00003704",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "chaplin",
                "APELLIDO": "rojas arohuillca",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "#ECB176",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "LABRADOR",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1413442800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "/istockphoto-1403669582-612x612.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "UqBWeBqjIiA=",
                "DNI": "28081816",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Toby",
                "APELLIDO": "-",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "#ECB176",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Basset Hound",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1569308400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "/istockphoto-1853686056-612x612.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "TNgCd6dfH1s=",
                "DNI": "78702100",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Toretto",
                "APELLIDO": "Anchante Aquise",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "#ECB176",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pitbull",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1698476400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "/istockphoto-1204670802-1024x1024.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "r4SxVySXB9g=",
                "DNI": "00010541",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "WANDA",
                "APELLIDO": "ALARCON LOZADA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "#ECB176",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pitbull",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1514098800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "/istockphoto-1403413438-612x612.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "A+PXZKQU8YE=",
                "DNI": "00013043",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Peluchín",
                "APELLIDO": "ílvarez Quintana",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "chitzu",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1536822000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "yC9z7yTe97o=",
                "DNI": "11604303",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Rocky Pedro ",
                "APELLIDO": "Gamarra Valencia ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Plateado y dorado",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Yorkshire Terrier",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1340780400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "https://worldpetsperu.com/img/mascota/0E6ECBD8766B1296DFFB848E42581F6F.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "ouTfyAUyEuM=",
                "DNI": "00002486",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Sepillin",
                "APELLIDO": "Ramirez Espiritu",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@Caramelo con plomo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Yorkshire Terrier",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1392361200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "NhPHtiff8TE=",
                "DNI": "00002490",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Sepillin",
                "APELLIDO": "Ramirez Espiritu",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@Caramelo con plomo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Yorkshire Terrier",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1419318000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "LPw/jG77jcw=",
                "DNI": "00002491",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Otto",
                "APELLIDO": "Ramirez espiritu",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@Caramelo con plomo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Yorkshire Terrier",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1419318000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "9m2MhWmQVB4=",
                "DNI": "00003038",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "pelu",
                "APELLIDO": "villanueva torres",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Bichon Maltes",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1510729200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "y4j7QkXivZE=",
                "DNI": "00001960",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Legolas Klint",
                "APELLIDO": "Zavala Velasques",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Bichon Maltes",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1481007600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "LQ32kpI6ZkA=",
                "DNI": "45877997",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Mandy",
                "APELLIDO": "Aparcana",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanca",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Bichon Maltes",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1508137200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "cMddGQwTIOQ=",
                "DNI": "16954797",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Oliver",
                "APELLIDO": "Rojas Martinez",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Bichon Maltes",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1564902000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "NqeP7ORnJDU=",
                "DNI": "00001759",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Boster Raul",
                "APELLIDO": "Camargo Pacheco",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro y Marron",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Salchicha",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1486364400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "12sQ43sRD3k=",
                "DNI": "00002873",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "pepa",
                "APELLIDO": "segovia aquije",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "cruzado chitzu con y",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1412578800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "ERXVdITxd/4=",
                "DNI": "00006033",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "CHICO",
                "APELLIDO": "VEGA PERAUNA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "COCKER SPANIEL",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1415602800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "s9knyq2S2Ao=",
                "DNI": "00006265",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "PEQUEñA",
                "APELLIDO": "OCHOA ROJAS",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@NEGRA",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUZADA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1204354800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "FXy1O9er5lk=",
                "DNI": "00006271",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "DAYA",
                "APELLIDO": "MELENDEZ MEZA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@NEGRO CON CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUZADA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1541919600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "PLND/XspDSA=",
                "DNI": "00003308",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Burqui",
                "APELLIDO": "Conqui Balareso",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@Caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "cocker",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1450162800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "RI6uvujONQ8=",
                "DNI": "00010740",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "COQUETA",
                "APELLIDO": "BERMUDEZ BENITES",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "cocker",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1545980400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "S3ZW041Xr/U=",
                "DNI": "14050421",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Molly María",
                "APELLIDO": "Candell Ayala",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco con cremita, no tiene colores oscuros un solo color",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cruce con Shih Tzu",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1550818800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "A5DE315ACB973B8E6DA83458C9E456D3.jpeg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "CIUCklhT8cs=",
                "DNI": "50720740",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Bamby Little ",
                "APELLIDO": "Huapaya Malásquez ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Rubio ",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cruce con Shih Tzu",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1325919600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "CD2641ACDA3609CF9EEB2CA8B8FD50A7.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "cKTrHtDIILw=",
                "DNI": "71552888",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Bojan ",
                "APELLIDO": "Krkic ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Castaño claro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Criollo Cruce Pequin",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1405580400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "45A5B0E008DF414F758D13F6023C9AA9.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "hnfO23Ur/fc=",
                "DNI": "01135413",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "PRINCESA FERNANDA ",
                "APELLIDO": "GUEVARA SALAZAR ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "BLANCO ",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Jack rusell Terrier",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1453878000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "0341610337A295BE1B50F527A701BC75.JPG",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "FS4QuV1lTIA=",
                "DNI": "00002519",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Blanca",
                "APELLIDO": "Mendoza",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Jack Rusell con Cock",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1482649200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "XjG4GHckxiA=",
                "DNI": "39437935",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Moni",
                "APELLIDO": "Rivera Araya",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco con negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pequines",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1408518000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "4193B6A29E1D8268860CFF6FAC06579A.jpeg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "/LmHSEcJxeI=",
                "DNI": "92288073",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "EMIR",
                "APELLIDO": "-",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Marrón ",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pequines",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1642662000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "CE20674ED7294F9B7C5B38433FC3AB31.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "73gbG9hqwfA=",
                "DNI": "48505843",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Kya",
                "APELLIDO": "Berrospi Medina",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUCE CON SCHNAUZER",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1520924400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "41C7E4D85D0493D12136D46FD81058BD.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "zC8QZbWbgfk=",
                "DNI": "82061478",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Sumaq ",
                "APELLIDO": "Urbano",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Marron-crema-blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRIOLLO- MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1450681200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "7D1FC9FA76B90A3954FA752B1E29A1D5.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Ld1a1pZfAUg=",
                "DNI": "00006207",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "PUPPY",
                "APELLIDO": "VILLANUEVA SOUS",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUCE PEQUINES CON C",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1408777200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "OvB75mojBtA=",
                "DNI": "00009443",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "CIELO",
                "APELLIDO": "DE LOS RIOS CARDENAS",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIHTZU CON POODLE",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1371020400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "2xAv0vUgAbc=",
                "DNI": "19309974",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Pelusa",
                "APELLIDO": "Alvarez",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco con una macha dorada en el inicio de la cola",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "PEQUINES CON SHITZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1444978800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "92B86DA424C1B48B9810CE7A448E3E9F.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "WeuEPUp4Xy0=",
                "DNI": "00004672",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Coco",
                "APELLIDO": "Noblecilla Aponte",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@Caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "chihuahua con pequinés",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1496127600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "SPCJLp5qmxM=",
                "DNI": "00008408",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "DUKE",
                "APELLIDO": "FERNANDEZ MORAN",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "chihuahua con pequinés",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1265007600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "GfyHe1V/5gY=",
                "DNI": "00008289",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "SAMANTA",
                "APELLIDO": "LIMACHE RAMOS",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "chihuahua con pequinés",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1386140400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "U59G/RbxJgs=",
                "DNI": "00012488",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Cuajinais",
                "APELLIDO": "Benites",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "chihuahua con pequinés",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1483945200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "BAEECwQWk4Y=",
                "DNI": "00002292",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Shoper",
                "APELLIDO": "Peralta Huaman",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUZADO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1378364400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "X3Amart10Rs=",
                "DNI": "00002638",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Hancok",
                "APELLIDO": "Cardenas Villanueva",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco y Marron",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUZADO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1504422000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "9qByFQhCDbk=",
                "DNI": "00004995",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "MINNIE",
                "APELLIDO": "VEGA ROJAS",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@MARRON",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUZADO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1171436400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "vXeHCrWzOc4=",
                "DNI": "00011169",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "MINI",
                "APELLIDO": "ORTIZ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUZADO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1461826800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "+w2f4gOGgSs=",
                "DNI": "00010857",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "MUñECA",
                "APELLIDO": "LOPEZ BRICEÑO",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUZADO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1550559600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "FzsBCmCJp+Y=",
                "DNI": "00006108",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "CHESTER",
                "APELLIDO": "CUBA SANTOS",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1531724400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "DSvYLUlHItc=",
                "DNI": "00001122",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Charlie",
                "APELLIDO": "Valle Santome",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo y Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1495609200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "0jlknpMWN+w=",
                "DNI": "00001123",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Charlotte",
                "APELLIDO": "Valle Santome",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1509606000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "VaDivAxANzE=",
                "DNI": "00004865",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "bills",
                "APELLIDO": "figueroa espinoza",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@blanco con mostaza",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1487055600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "6VR6S1a/Ma0=",
                "DNI": "00004982",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "bobby",
                "APELLIDO": "castillo martinez",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@BEIGE CON BLANCO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1449039600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "wKHh/RyNAuE=",
                "DNI": "11059509",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Tomás",
                "APELLIDO": "calderón calderón",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "café con leche",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1484982000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "IFWPwetRkBE=",
                "DNI": "00002158",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Niño",
                "APELLIDO": "Huayta Medina",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Marron Claro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1387522800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "hV96IowMx4c=",
                "DNI": "00007824",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "estrella",
                "APELLIDO": "contreras rojas",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1439708400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "8BATLn+KOXk=",
                "DNI": "00007876",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "MELA",
                "APELLIDO": "TORRES CALDERON",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CREMA",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1368601200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "SyfoUtgq/EA=",
                "DNI": "00003564",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "rocky",
                "APELLIDO": "ortiz sanchez",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1535871600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "jMDp5Npk+Hk=",
                "DNI": "00007127",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "luna",
                "APELLIDO": "Tenazoa Torres",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@marrÓn",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1508482800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "d9YdCyYM0rs=",
                "DNI": "00007295",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "patuto docky",
                "APELLIDO": "ganboa espinoza",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@negro con rayas ama",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHUAHUA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1458025200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "cxj69K/Q59o=",
                "DNI": "00013029",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Buba",
                "APELLIDO": "Padilla",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@Pardo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "PITBULL AMERICAN TER",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1276326000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "/WfACnbvWR8=",
                "DNI": "21743673",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Flash",
                "APELLIDO": "Cubas Sanchez",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "cockapoo",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1640415600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "37AEA85A2B0868F1579F0C4C1166E030.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "afWzCy2+xBE=",
                "DNI": "00010734",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "CHATA",
                "APELLIDO": "LAZARTE ERAZO",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@BLANCO CON CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1477983600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "qfbarHWMpsA=",
                "DNI": "00002384",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "WINNIE",
                "APELLIDO": "VILLALOBOS NAPAN",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Gris",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1453100400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "mMZDrfK323w=",
                "DNI": "00002439",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Puppy",
                "APELLIDO": "Reyes",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "vacio_",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1429167600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Bu8FmcRemMo=",
                "DNI": "00013057",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "papi",
                "APELLIDO": "torres vargas",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1192777200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "RXZVZSL+VZA=",
                "DNI": "00012893",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Boby",
                "APELLIDO": "Abrigo Godo",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1483340400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "aSWfFnPUMNA=",
                "DNI": "00012906",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Cabezón Duque",
                "APELLIDO": "Lopez Sanguines",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco y Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1458025200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "TmpwkLJBMv8=",
                "DNI": "00002798",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Minato",
                "APELLIDO": "Delgado Morales",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@Plomo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1402729200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "tiXVRhPgOcI=",
                "DNI": "00003045",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "casimiro",
                "APELLIDO": "flores dupeyratt",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "vacio_",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1456988400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "V6OkU8akq5Q=",
                "DNI": "00003095",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "machin alberto",
                "APELLIDO": "HUERTA GIRON",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1365750000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "VqexYYN5R6Q=",
                "DNI": "00009879",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "RAIDER",
                "APELLIDO": "CASTRO PEREDA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CREMA",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1445151600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "SZsYHvho6UE=",
                "DNI": "00010223",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Gringo",
                "APELLIDO": "Molina Calixto",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@BLANCO CON CREMA",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1534316400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "rKf4U6jJf5c=",
                "DNI": "00010296",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "BANANIN",
                "APELLIDO": "CARBAJAL VARGAS",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1242370800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "0UMlGiHz3D0=",
                "DNI": "00004616",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "PELUCHINA",
                "APELLIDO": "RAMOS ORTEGA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@MARRON",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(-62135571600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "M0c61K/vnwQ=",
                "DNI": "00004761",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "puky",
                "APELLIDO": "cossio sanchez",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1331794800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "eqSeIa5+aa0=",
                "DNI": "00005189",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "chinita",
                "APELLIDO": "huamani tello",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "vacio_",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1533625200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "vkrRlFqqhjg=",
                "DNI": "00005684",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "KEKA",
                "APELLIDO": "CALZADA MONTELUIS",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1496300400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "wN37BhJ5M2o=",
                "DNI": "00005779",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "CHUBACA",
                "APELLIDO": "VILLAFUERTE MICHUE",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@MARRON",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1525676400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "dTpM/XXuW9I=",
                "DNI": "00008398",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "KIARA ALEXANDRA",
                "APELLIDO": "MAIGUA SEGIL",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@EL COLOR BLANCO Y P",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1485932400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "KN8mT8ZEFRU=",
                "DNI": "00008427",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "ALASKA",
                "APELLIDO": "HUAMANI MATAMOROS",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Marron y Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(-62135571600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "rKO3WSNTjso=",
                "DNI": "93579801",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Fifi",
                "APELLIDO": "Olivares Santiago",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanca y negra",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU CRUZADA",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1510815600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "7BWFy9ugrtE=",
                "DNI": "99249431",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Chispa",
                "APELLIDO": "Guzman Calla",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Perro sin pelo del P",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1294470000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "032CA2B67AC5D51DDACDDB871669F530.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "CcQ1KGnfTmk=",
                "DNI": "71088779",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Sansón",
                "APELLIDO": "Mosqueda",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Bichón Maltés",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1561532400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "0EEA7AF2AE4B57FD73492B482368E635.png",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "MF/e3IaiwcQ=",
                "DNI": "29164487",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Yango",
                "APELLIDO": "Vicente Luque",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Tricolor",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Shitzu",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1537945200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "36052ED7B876003A44E509D3A4D446EC.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "JsrO0qe7DYg=",
                "DNI": "15328280",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Lobito",
                "APELLIDO": "Pacherres Canales",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Gris acaramelado",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pomsky",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1507878000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "2C29C841BF5A9603392BB17494E4E7C3.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "GtsXHSKgFRs=",
                "DNI": "45076389",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "KRATOS ",
                "APELLIDO": "AMAO ENCALADA ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "MARRÓN CON BEIGE",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "cruce de pequines",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1547103600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "FB5383C3FACFD8A6D2448A64854C0C75.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Co8UklX7ayI=",
                "DNI": "28986093",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Luna",
                "APELLIDO": "Ruiz lapa",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanca con manchas negras ",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "cruzada con pequines",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1577516400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "05039260BAC431BC29C70967D9C485F7.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "BHguU8wnhVM=",
                "DNI": "33480663",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "VENUS ",
                "APELLIDO": "ZAPATA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Gris con negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1641798000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "3B3C3E32D83530D119D5871DFC26EF15.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "r9MKpq7RSnY=",
                "DNI": "00006063",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "PRINCESA",
                "APELLIDO": "SAUTISTEBAN",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y Pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUCE SCHNAUZER-SHIH",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1368601200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "QyE24VmEUHM=",
                "DNI": "21026674",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Nathalie",
                "APELLIDO": "Lopez",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "MARRON",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO PEQUINES",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1319958000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "EB27B5382E9D6E1AE84947113126B570.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Do7Xqr4Sy1I=",
                "DNI": "13867149",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Kira",
                "APELLIDO": "Norvani",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Manteca",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pomerania",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1543734000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "F919E186D4651F1115CC7A58BFBC3860.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "blZBNCU3nr4=",
                "DNI": "00008104",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "mailla",
                "APELLIDO": "ramos mejia",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y Pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "shih tzu/schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1521442800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "HPG61PP/l4k=",
                "DNI": "33906354",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Pipo",
                "APELLIDO": "Villegas",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Pardo claro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "mestizo cruzado",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1515222000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "3703F670B0F018ECECA03C233324BE34.jpeg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "GyeeY7Qz+XI=",
                "DNI": "43832651",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Sammy Antonela",
                "APELLIDO": "Valencia Horna",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Canela",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Shitzu con pekines",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1223622000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "6C4A64BDD10692B181045D36B4976542.jpeg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "iYujI0+ERZs=",
                "DNI": "00010809",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "gringo",
                "APELLIDO": "rojas",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@blanco con caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "crillo",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1317625200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "ua+/EZJuh2w=",
                "DNI": "03770335",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Walker",
                "APELLIDO": "Diaz leon",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "C/ PEKINES",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(-1651251600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "A962A696F2D82CFF7B5425A562468A46.jpeg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "hOaVlhCnSus=",
                "DNI": "44007415",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Terry Darcy",
                "APELLIDO": "Vilca",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco con orejas grises",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Shih Tzu",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1593846000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "111EDEEAB67B7DF58E4C1886B86C16D2.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "JQRrMdqUAJI=",
                "DNI": "26215095",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "LIANA ",
                "APELLIDO": "Vasquez Avalos",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro ",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Shih Tzu",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1305097200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "B6E4EF20C12796291BD9D42D6844078B.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "sQu02lsjh7A=",
                "DNI": "34880509",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Peluchín",
                "APELLIDO": "Baldeón Zárate",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU CON MALTES",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1350025200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "E5B668279A0BBC3DEA3AEA08988130EF.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "EwuV4/izE7g=",
                "DNI": "00010626",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "poker",
                "APELLIDO": "collahuasi",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@blanco-caoba",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker con schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1484463600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "S5v0rb2y7+Q=",
                "DNI": "00007678",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "tiny",
                "APELLIDO": "Ortiz Idrogo",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y Pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker con schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1519542000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "K3Q6NIpuzmI=",
                "DNI": "00007740",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "chata",
                "APELLIDO": "alarcon",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y Pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker con schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1308985200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Kxyn3Whyp50=",
                "DNI": "00007741",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "lulu",
                "APELLIDO": "alarcon",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y Pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker con schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1373871600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "ZJaBI5FjM2E=",
                "DNI": "00012551",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "HARU",
                "APELLIDO": "PEREZ SARMIENTO",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y Pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker con schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1450508400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "MoB7qED/zW0=",
                "DNI": "00012805",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Ramis",
                "APELLIDO": "Azabache GONZALES",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y Pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker con schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1476601200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Mw21z5+z3Wk=",
                "DNI": "00003175",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "chatin",
                "APELLIDO": "malaga sucasaire",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Gris y Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker con schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1322463600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "b2Gq0IrxwXw=",
                "DNI": "29077859",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "lucky pekes",
                "APELLIDO": "GARCIA ROMAINVILLE",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker con schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1438844400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "38E9EB3B3256EB13D29FA963CF867EE0.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "QLSazhPvtg4=",
                "DNI": "00001463",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Gutchi",
                "APELLIDO": "Gutiérrez Uchiri",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y Pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker con schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1499670000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "mV2RT4rWqi4=",
                "DNI": "00010983",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "chester",
                "APELLIDO": "gomero",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y Pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker con schnauzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1431673200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "FnLT6G6XGSA=",
                "DNI": "00001081",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Nino",
                "APELLIDO": "Luyo",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pekines",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1488438000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "j8qad/HdeZs=",
                "DNI": "00003244",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "spike",
                "APELLIDO": "fuentes lozada",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@caramelo con blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pekines",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1474354800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "BAh0iQZL7B0=",
                "DNI": "00002174",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Chato",
                "APELLIDO": "Vivas Canessa",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pekines",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1329807600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "wg9Ik8E/Kbw=",
                "DNI": "00012939",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Lucke",
                "APELLIDO": "Alvarez Mundaca",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pekines",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1432364400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "lXDwEx22QSQ=",
                "DNI": "92802575",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Shawi",
                "APELLIDO": "Sayago Anticona",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Castaño",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pekines",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1428476400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "BEF093F38FF9139906C62F9F970780D2.PNG",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "hBxdqV44kts=",
                "DNI": "97163526",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Gabriela del Pilar ",
                "APELLIDO": "Marchena Nuñez",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beish",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Chusca",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1139295600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "6omxZOyLLuk=",
                "DNI": "00001306",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "dasdsad",
                "APELLIDO": "asdasd",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "ddasdasd",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(-62135571600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "1BQzwe7SQAs=",
                "DNI": "00001307",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "dasdsad",
                "APELLIDO": "asdasd",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "ddasdasd",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(-62135571600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "M1UmFSvFQmc=",
                "DNI": "00005904",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "NOAH",
                "APELLIDO": "RAMIREZ HUERTA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "vacio_",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "American Bully",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1534402800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "X3KQ1sQU1qU=",
                "DNI": "00008599",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "LLORON",
                "APELLIDO": "JUAREZ OLIVERA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1394434800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "a2mnqhxHHDA=",
                "DNI": "00009199",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "ODDY",
                "APELLIDO": "MEZA ROMERO",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Canela",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1466319600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "KF/20ZIvbSM=",
                "DNI": "00009219",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "SANCHU",
                "APELLIDO": "PAJUELO LOBTON",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Chocolate",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1513753200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "H9lEDEdrRBc=",
                "DNI": "00009280",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "KALOTO",
                "APELLIDO": "COCHAS PAKAYA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1392015600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "TyQFFz79Kwk=",
                "DNI": "00009508",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "SCOT",
                "APELLIDO": "GUTIERREZ LLACTA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CREMA",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1468566000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "CbETRL7KQUk=",
                "DNI": "99412079",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "odie lobo",
                "APELLIDO": "calderon veramendi",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1488351600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "frGtLyu5Kdc=",
                "DNI": "00001066",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Toby",
                "APELLIDO": "Huaynasi Aguirre",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Canela",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1509087600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Gh/Ws+iILaI=",
                "DNI": "00001350",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Scott",
                "APELLIDO": "Vilcamich Alfaro",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Marron Oscuro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1451199600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "0cdQiZIhakA=",
                "DNI": "00005946",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "ROCKY",
                "APELLIDO": "BAZAN CASTILLO",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Naranja",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1539932400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "jVajU4bQN0o=",
                "DNI": "00006593",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Chiwua",
                "APELLIDO": "Campomanes Picon",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1392015600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "gih+yJ03MxU=",
                "DNI": "00011066",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Tissy Esteysi",
                "APELLIDO": "FUENTES",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro y Marron",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1539586800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "5leVOrMZ8lE=",
                "DNI": "00011270",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "cuchis",
                "APELLIDO": "nevado",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@negro atigrado",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1544857200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "uk0xjW3rVLQ=",
                "DNI": "66900328",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "CHOCOLATE",
                "APELLIDO": "-",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Chocolate",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1573110000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "CABFC7840D403A5EA21E18553D8EB1A9.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "rFjPHMdDSfY=",
                "DNI": "60041283",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Pipitas",
                "APELLIDO": "Ojeda Olaya",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1609570800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "803BF72233078312F4D20741BD8837FA.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "yhWmNdkgvIA=",
                "DNI": "00007904",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "LOLA",
                "APELLIDO": "HURTADO CRUZ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Marron Claro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1521097200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "qpm1u8qHLfQ=",
                "DNI": "00008207",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "moly",
                "APELLIDO": "orihuela toribio",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro y Marron",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1539846000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "+wyhkzsCCw0=",
                "DNI": "00008285",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "FLIPER",
                "APELLIDO": "MONTERO CASTILLO",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@BLANCO Y CREMA",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1476774000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "z82tYBPfKqs=",
                "DNI": "00012457",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Chiko",
                "APELLIDO": "Condori Cosi",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@blanco y crema",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZO",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1261551600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "3g/sHqmWfgk=",
                "DNI": "00003941",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Doki",
                "APELLIDO": "Balbuena Zavalaga",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@Pequines",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "PEQUINES",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1314860400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "cxrnYBYFasQ=",
                "DNI": "00009032",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "puka",
                "APELLIDO": "vicente angulo",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "PEQUINES",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1446793200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "s95l19TSNbg=",
                "DNI": "00002787",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Chiquitin",
                "APELLIDO": "Manco Cardenas",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@Caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "PEQUINES",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1493017200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "P5bveM5JpA4=",
                "DNI": "72875216",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Franchesco ",
                "APELLIDO": "Gonzales",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cruce poodle con chihuahua",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1316502000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "70C6B73ED4BCA86C538497655CAAB769.jpeg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "xHszPwDqAUE=",
                "DNI": "00005824",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "BOMBOM",
                "APELLIDO": "SANCHEZ LAYZA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Shih tzu minitoy",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1124002800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "SVEuKaGlFoI=",
                "DNI": "92428122",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Thomas",
                "APELLIDO": "collazos Meza",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Crema",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Cocker Spaniel cruzado",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1186729200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "A1D78C2DED66A5F87A13EABC8810D0DE.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "QQPmzEx+2ik=",
                "DNI": "00004300",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "chato",
                "APELLIDO": "sulca escriba",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Marron Claro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "yorshire",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1319094000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "vDmmAl2jadE=",
                "DNI": "64445616",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Príncipe",
                "APELLIDO": "Valencia Espinoza",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "cruzado mestizo",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1538377200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "kC1TYC2UT9w=",
                "DNI": "00010498",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Beglu",
                "APELLIDO": "Loayza Rivera",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Albino",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1484463600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "62Xx59Q2hFY=",
                "DNI": "00007189",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "ROCKY",
                "APELLIDO": "INOCENCIA TAPIA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@MARRON CON MANCHAS",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUZADO CON PEQUINES",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1442473200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "vDny8EZr+dw=",
                "DNI": "50329625",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Keyla",
                "APELLIDO": "Paredes Angeludis",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Marrón claro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pomerania / Pekinez",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1434265200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "gWk+il/XK+Y=",
                "DNI": "41086000",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "NIKKI KEYSI ",
                "APELLIDO": "MAYTA SILVA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Gris claro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIHTZU CON PEQUINES",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1468566000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "4A7D6903E1038E62204ACBDF1B8FFC10.jpeg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "bz91kkg6CsM=",
                "DNI": "71737887",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Zaqueo Guillermo ",
                "APELLIDO": "Yacolca Farfán",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "MESTIZ",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1256194800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "29F30BCECAFF4C40C0B778B6EB7CB1B4.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "U01PEl5ha0s=",
                "DNI": "73146951",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Motita ",
                "APELLIDO": "Rafael Suárez ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanca",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CHIHTZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1557039600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "479761079A2771A7A1863678F85198DA.JPG",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "kX3VHK4o1Js=",
                "DNI": "60976761",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Sanson",
                "APELLIDO": "Velasquez ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal pimienta ",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Schnauzer miniatura",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1219906800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "E70D01CD3CF78D67A32C7CE01165DAFD.jpeg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Q/DWf1uHX5g=",
                "DNI": "00011902",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Siri",
                "APELLIDO": "Gomez Vila",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro y Marron",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pinscher Dóberman",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1415775600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Cou55OYwaS4=",
                "DNI": "00012016",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "PELUCHIN",
                "APELLIDO": "BRAVO TINCOPA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@crema",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "PEQUINí‰S/SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1132988400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Ic8Qd6BQG6Q=",
                "DNI": "91113482",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Mateo",
                "APELLIDO": "Sánchez Pérez",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Mestizo Shitzu",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1450335600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "1F5B519CDE67AC0D0FCAB419AA3048A4.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Dw93YWs74Jc=",
                "DNI": "57156642",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "motta",
                "APELLIDO": "chavez castañeda",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco con beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1331708400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "515B37A5BB29C8EA5C7D919139B22971.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "UMfIvB0kPHE=",
                "DNI": "69527671",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Kira Valentina",
                "APELLIDO": "Cáceres Cucho",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1599721200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "C3C8668B6DD21C92C297BBE76C9811E4.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "lA23dya34Ic=",
                "DNI": "17919573",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Pipo",
                "APELLIDO": "Capa Macedo ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Marrón ",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "PEQUINES",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1441868400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "B6150CB58F026E3A23FF45CDAFDDC59B.jpeg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "dYaefRAIU2I=",
                "DNI": "62127670",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Motthy",
                "APELLIDO": "Blas Principe",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pequinés",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1438239600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "F861304A6D022C22E70244EBC8F87F49.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "rsg1ZtVocRI=",
                "DNI": "60399377",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Dino",
                "APELLIDO": "Villa Ortiz",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SCHNAUZER c/SHIHTZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1275721200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "/J2R4FIpa20=",
                "DNI": "09833887",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Bruno",
                "APELLIDO": "Gonzales Junesch",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "dorado con plateado",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "poodle",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1286866800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "cH5w3itQnWk=",
                "DNI": "00011779",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "NASH RUPERTO",
                "APELLIDO": "GUTIERREZ SILVA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CREMA",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "poodle",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1392534000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "Oft9V8l50xQ=",
                "DNI": "00008142",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "BASTER",
                "APELLIDO": "ROMERO RUIZ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Criollo",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1507705200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "rTrAmcxYx4c=",
                "DNI": "00010601",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "TZY",
                "APELLIDO": "GUSMAN VALLEJOS",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Criollo",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1396681200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "5fROOBEeUsI=",
                "DNI": "00002505",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Doky",
                "APELLIDO": "Espinoza Piedra",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Criollo",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1279177200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "0hFdQOFZ1B4=",
                "DNI": "00002217",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Panza",
                "APELLIDO": "Lopez Saravia",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Criollo",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1491894000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "OuZytFFnNk0=",
                "DNI": "00001474",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Belona",
                "APELLIDO": "Avellaneda Jurado",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Pitbull american",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1481785200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "QxzfgEuIR/s=",
                "DNI": "00011213",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "FEDERICA",
                "APELLIDO": "SUAREZ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CARAMELO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Bulldog Frances",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1494831600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "EKKLQ05rOvY=",
                "DNI": "00010569",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "MANCHAS",
                "APELLIDO": "MONTALVO RAMIREZ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro y Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Chihuahua con poodle",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1531378800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "j6Cmd3H2va8=",
                "DNI": "00003432",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "toby",
                "APELLIDO": "yucra rojas",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "schnawzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1541487600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "wJJqXsvSQxY=",
                "DNI": "00005803",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "max",
                "APELLIDO": "atauje leiva",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Sal y Pimienta",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "schnawzer",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1455260400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "jA1CMXOW958=",
                "DNI": "64057907",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Pluto",
                "APELLIDO": "Nashinato Antezana",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Caramelo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "CRUCE CON PEQUINES",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1448002800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "66F5B8DBA10C6A884D21E6A8AE873F50.jpeg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "mMXWRlRHjyk=",
                "DNI": "00012654",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Peluchin",
                "APELLIDO": "Valdez Castro",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Maltez con shih tzu",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1341903600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "bO6XJ4qFHLg=",
                "DNI": "11698382",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Nino Stitch",
                "APELLIDO": "Rodriguez ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "Chihuahua con pequinés",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1276153200000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "B4FC37A87D27D4F315845A8E9C99706F.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "r0ORpmUPm1E=",
                "DNI": "71502641",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Useuun",
                "APELLIDO": "Mamani Alanya",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro con pecho blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1588316400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "5E454262F77964A859B4653CE85C219C.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "fRkmkF55PCQ=",
                "DNI": "00008781",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "OJON",
                "APELLIDO": "CORONADO POICON",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@CARAMELO OSCURO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1290236400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "wYM6b/Ngv3Q=",
                "DNI": "00009266",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "goofy",
                "APELLIDO": "gonzalo orihuela",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco y Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1513062000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "lrw8kVSqGi0=",
                "DNI": "00005580",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "VEILOR",
                "APELLIDO": "ATAULCO ZELAYA",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@MARRON",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1497250800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "y+DvUKHXljo=",
                "DNI": "00006650",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "nena",
                "APELLIDO": "gutierrez palomino",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1412751600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "OeiomVrlj44=",
                "DNI": "00007068",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "GORDA",
                "APELLIDO": "CALANTE CALDERON",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1515567600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "kdY0FVCiooM=",
                "DNI": "00001907",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Scooby",
                "APELLIDO": "Acuña Avellanada",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1448262000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "5rHFtx/JCfc=",
                "DNI": "00005899",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Chespy",
                "APELLIDO": "MartÍnez Quineche",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1005030000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "PTZadOG03RE=",
                "DNI": "00002020",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Chester",
                "APELLIDO": "Diaz Bejarano",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Negro y Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1315810800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "2fEWrEq4/vk=",
                "DNI": "65867790",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "ORAZIO",
                "APELLIDO": "MONTALVAN CAYCAY",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "BLANCO CON NEGRO",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1526108400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "n9/PUd4mwuk=",
                "DNI": "26404258",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Makuko",
                "APELLIDO": "Miranda Velasquez ",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Plomo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1456815600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "1EF039B8C360653698D917512EB41140.jpg",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "r2/LHTPgTpY=",
                "DNI": "00002347",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Dasha",
                "APELLIDO": "Roncal Caycho",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco y Marron",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1482044400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "MYMSzcxa/Z4=",
                "DNI": "00002414",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "toffy",
                "APELLIDO": "velasquez pizarro",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "@blanco con beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1487919600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "QdqlzSWJnAI=",
                "DNI": "00002517",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Jimmy",
                "APELLIDO": "Chacon Antonio",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(988700400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "/fkfwyt9Xto=",
                "DNI": "00010347",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "CHATO",
                "APELLIDO": "EFFIO NOLE",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1488351600000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Hembra",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "pZWavySABIU=",
                "DNI": "00003126",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "kiara",
                "APELLIDO": "shinigami",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "vacio_",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1398754800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "o+ebJv+Cpz0=",
                "DNI": "00003611",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "odin",
                "APELLIDO": "Terrones gamarra",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Amarillo",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1415602800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "bK6nGsTlnPo=",
                "DNI": "00012960",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Duke",
                "APELLIDO": "Zevallos Villanueva",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1496732400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "ADTo1dTR+Sg=",
                "DNI": "00013024",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Mateo",
                "APELLIDO": "Callan Cerrudo",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Beige",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1421218800000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "WCvUcDCswUA=",
                "DNI": "00013037",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Gismo",
                "APELLIDO": "Lopez De La Cruz",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1453532400000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            },
            {
                "__type": "ENTIDAD.EMascota",
                "TAMANO": "Chico",
                "TIPO": null,
                "RAZA": null,
                "CALIFICACION": null,
                "SEXO": "Macho",
                "CORREO": null,
                "OBSERVACION": null,
                "TELEFONO": null,
                "CELULAR": null,
                "ID": 0,
                "ID_ENCRIP": "jG/oevbPTWo=",
                "DNI": "00012494",
                "USUARIO_ID": 0,
                "USUARIO": 0,
                "NOMBRE": "Niko",
                "APELLIDO": "Zegarra Porras",
                "COD_MICROCHIP": null,
                "ESPECIE": null,
                "MASCOTA_TIPO_ID": 0,
                "MASCOTA_RAZA_ID": 0,
                "COLOR": "Blanco y Marron",
                "TIPO_DSC": "CANINO",
                "RAZA_DSC": "SHIH TZU",
                "BIOGRAFIA": null,
                "FEC_NAC": "\/Date(1439622000000)\/",
                "FEC_CREA": null,
                "FEC_EMI": null,
                "FEC_CAD": null,
                "FAMILIARP": null,
                "DNIP": null,
                "TELEFONOP": null,
                "FAMILIARM": null,
                "DNIM": null,
                "TELEFONOM": null,
                "GEOGRAFIA_ID": 0,
                "DEPARTAMENTO": null,
                "PROVINCIA": null,
                "DISTRITO": null,
                "DIRECCION": null,
                "PISO": null,
                "REFERENCIA": null,
                "GALERIA_ID": 0,
                "FOTO": "",
                "EXTENSION": null,
                "CASTRADO": 0,
                "VISITA": 0,
                "ALERGIA_MEDICAMENTO": 0,
                "VACUNACION": 0,
                "ANTIRRABICA": 0,
                "FEC_ANTIRRABICA": null,
                "ALERGIA": 0,
                "ALERGIA_DSC": null,
                "ENFERMEDAD": 0,
                "ENFERMEDAD_DSC": null,
                "FEC_DESPARACITACION": null,
                "SEXTUPLE": 0,
                "FEC_QUINTUPLE": null,
                "QUINTUPLE": 0,
                "FEC_SEXTUPLE": null,
                "TRIPLEFEL": 0,
                "FEC_TRIPLEFEL": null,
                "LEUCEMIA": 0,
                "FEC_LEUCEMIA": null,
                "LIMP_DENTAL": 0,
                "OPCION": 0,
                "ESTADO": 0,
                "lMASCOTA": null,
                "NOMBRE_PRE": null,
                "APELLIDO_PRE": null,
                "PASSWORD": null,
                "MES": 0,
                "INDICE": 0,
                "FEC_INI": null,
                "FEC_FIN": null
            }
       ];






export const filteredResult = [
    {
        id:1,
        SEXO:"Macho",
        TIPO:"Canino",
        TAMANO:"Grande",
        CALIFICACION:"Amistoso",
        FOTO:"/dog.jpg",
        COLOR:"#835532"
    },
    {
        id:2,
        SEXO:"Macho",
        TIPO:"Canino",
        TAMANO:"Grande",
        CALIFICACION:"Amistoso",
        FOTO:"/istockphoto-1204670802-1024x1024.jpg",
        COLOR:"#a3784f",
    },
    {
        id:3,
        SEXO:"Macho",
        TIPO:"Canino",
        TAMANO:"Grande",
        CALIFICACION:"Amistoso",
        FOTO:"/istockphoto-1403669582-612x612.jpg",
        COLOR:"#a3784f"
     },
    {
        id:4,
        SEXO:"Macho",
        TIPO:"Canino",
        TAMANO:"Grande",
        CALIFICACION:"Amistoso",
        FOTO:"/david-lezcano-m-Doa-GTrUw-unsplash.jpg",
        COLOR:"#000"
    },
    {
        id:5,
        SEXO:"Macho",
        TIPO:"Canino",
        TAMANO:"Grande",
        CALIFICACION:"Amistoso",
        FOTO:"/james-barker-v3-zcCWMjgM-unsplash.jpg",
        COLOR:"#423327"
    },
    {
        id:6,
        SEXO:"Hembra",
        TIPO:"Felino",
        TAMANO:"Chico",
        CALIFICACION:"Amistoso",
        FOTO:"/FE61F09719828F0782160A602D2BDD19.jpeg",
        COLOR:"#8e6b51",
    },
    {
        id:7,
        SEXO:"Hembra",
        TIPO:"Felino",
        TAMANO:"Chico",
        CALIFICACION:"Amistoso",
        FOTO:"/FBF8DAE56F29A6287609141B633941FF.jpg",
        COLOR:"#807374"
    },
    {
        id:8,
        SEXO:"Hembra",
        TIPO:"Felino",
        TAMANO:"Chico",
        CALIFICACION:"Amistoso",
        FOTO:"/6E7C956EB18AD0E0BD932D39B2781881.jpg",
        COLOR:"#dfa972",
    },
    {
        id:9,
        SEXO:"Hembra",
        TIPO:"Felino",
        TAMANO:"Chico",
        CALIFICACION:"Amistoso",
        FOTO:"/F950866095596DB1D471FFBC7694FB0A.jpeg",
        COLOR:"#c6c9c6",
    },
    {
        id:10,
        SEXO:"Hembra",
        TIPO:"Felino",
        TAMANO:"Chico",
        CALIFICACION:"Amistoso",
        FOTO:"/E7742193DF6215BD5815DCB76BD18906.jpg",
        COLOR:"#000",
    },

    {
        id:11,
        SEXO:"Macho",
        TIPO:"Ave",
        TAMANO:"Todos",
        CALIFICACION:"Agresivo",
        FOTO:"/david-clode-K4vHlGVX0Hs-unsplash.jpg",
        COLOR:"#FFFF00",
    },
    {
        id:12,
        SEXO:"Macho",
        TIPO:"Ave",
        TAMANO:"Todos",
        CALIFICACION:"Agresivo",
        FOTO:"/christopher-alvarenga-3osGqRRtQBE-unsplash.jpg",
        COLOR:"#f00",
    },
    {
        id:13,
        SEXO:"Macho",
        TIPO:"Ave",
        TAMANO:"Todos",
        CALIFICACION:"Agresivo",
        FOTO:"/akshay-madan-Ugl5m_JgBvI-unsplash.jpg",
        COLOR:"#3f5222",
    },
    {
        id:14,
        SEXO:"Macho",
        TIPO:"Ave",
        TAMANO:"Todos",
        CALIFICACION:"Agresivo",
        FOTO:"/zdenek-machacek-OlKkCmToXEs-unsplash.jpg",
        COLOR:"#3fa617",
    },
    {
        id:15,
        SEXO:"Macho",
        TIPO:"Ave",
        TAMANO:"Todos",
        CALIFICACION:"Agresivo",
        FOTO:"/sk-yeong-MOAzs7daQBU-unsplash.jpg",
        COLOR:"#5f6e0c",
    },
]


export const findResult = [
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "lpfPAAU10H0=",
            "DNI": "32626217",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "OREO",
            "APELLIDO": "BARJA OLIVERA",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "#6b7175",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1663311600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "https://worldpetsperu.com/img/mascota/CE535D8CDB7047CA67AFEA7F9CDDFE34.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "tsh9weHlA2w=",
            "DNI": "65517056",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Francesco ",
            "APELLIDO": "Altamirano ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "#6b7175",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1534575600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "12889A94EAB18CD6EDA862E717727CD2.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "9/Dp4EvkBlU=",
            "DNI": "64870574",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Mino",
            "APELLIDO": "Tezen Saavedra ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "#aa8761",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1610953200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "1594C61F0F22148267F1D0974A300BD3.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "zOZ/W4qvIf8=",
            "DNI": "41935931",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Sebastian Alfonso",
            "APELLIDO": "Labarca Ponte",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "#000",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1613890800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "E7742193DF6215BD5815DCB76BD18906.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "yfbjiHpmUbE=",
            "DNI": "51532647",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Papi ",
            "APELLIDO": "Román Rochabrún ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "#775d46",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1516518000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "/cat.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "tPYgEVLSa9k=",
            "DNI": "97804292",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "TIGGER BASTIAN",
            "APELLIDO": "SALAZAR MACHADO",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "#807374",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1594796400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "/FBF8DAE56F29A6287609141B633941FF.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "EkO2U+r7Vb0=",
            "DNI": "28857328",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Jhuen",
            "APELLIDO": "NORIEGA SOLORZANO ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "#aa8761",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1528441200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "/amber-kipp-75715CVEJhI-unsplash.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "p35JELciVmI=",
            "DNI": "30930674",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Linda",
            "APELLIDO": "Berrospi Zapata",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "#d3d0cd",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1391238000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "/tran-mau-tri-tam-FbhNdD1ow2g-unsplash.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "jzXBH8slyfg=",
            "DNI": "87632710",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Gringo",
            "APELLIDO": "Espinoza",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "#aa8761",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1612767600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "6E7C956EB18AD0E0BD932D39B2781881.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "mavVZNEKzQI=",
            "DNI": "42582198",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Napoléon ",
            "APELLIDO": "Alegría Lefebvre",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1549004400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "D9C9A056F6052FFBFA3526BE3478D45E.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "5vqFbIEeloI=",
            "DNI": "21215250",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Gringo",
            "APELLIDO": "Alegría Lefebvre ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Naranja y blanco ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1546326000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "112708476D34CDD65298D9245A126D8A.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "CQlG3L0xYrs=",
            "DNI": "31190467",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Francesco ",
            "APELLIDO": "Alegría Lefebvre ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Plomo y blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1299654000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "796322999A680991A177A4CD2E4843B7.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "HoGvteCZz04=",
            "DNI": "29515504",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Jack",
            "APELLIDO": "Cervantes Reyna",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1475305200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "C6087C6325740286128DEF0C490B7AC6.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "9wgdYpAVMME=",
            "DNI": "53683461",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Micaelo Francesco ",
            "APELLIDO": "Diaz Roa",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Anaranjado atigrado",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1479106800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "0e1nFr9XmXQ=",
            "DNI": "23365389",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Odín",
            "APELLIDO": "Asencio Velazco ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco y negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestizo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1536303600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "79DACEB948005DEA8A61BD189CB4D468.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "cTa4xJpgBFY=",
            "DNI": "42583458",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Luna",
            "APELLIDO": "NORIEGA SOLORZANO ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negra con manchas amarillas",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestiza",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1461654000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "053D75D54621041EDDE72F8BB7203A48.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "tDBBk7FKwWw=",
            "DNI": "88571871",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Yuraq",
            "APELLIDO": "Nuñez Cusihuallpa",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "europeo comun",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1441177200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "51B68F5195A2B629640C55358CD4A7F5.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "3Qt5pHaAUBU=",
            "DNI": "49792662",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Simba",
            "APELLIDO": "Castañeda Bulnes",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "anaranjado",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "europeo comun",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1545375600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "GO2BNs36C00=",
            "DNI": "72087887",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Pancho",
            "APELLIDO": "Barbosa ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Gris con blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "europeo comun",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1560668400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "B271BF0CCA0510FA3C8A0E3B0213A0BA.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "zIGF3b311EU=",
            "DNI": "93075172",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "GRINGO",
            "APELLIDO": "ARÓSQUIPA ORUÉ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "NARANJA Y BLANCO",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "europeo comun",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1554188400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "C8E601F63CFC5D588274DE0C90F3AC1B.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "+JrMrmvNISk=",
            "DNI": "39125793",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "machito",
            "APELLIDO": "rojas sánchez",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "blanco con negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "europeo comun",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1433746800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "C8EE5C5F3624E6BFB00270207FD1EE49.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "DBqchhWo+8I=",
            "DNI": "30337413",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "gringo",
            "APELLIDO": "rojas sánchez",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "marron",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "europeo comun",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1433746800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "3CA5A9D2F776398791637CEEADD3601F.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "DHAEU71AnAY=",
            "DNI": "00001176",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Titi",
            "APELLIDO": "White",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Pseudo-angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(-62135571600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "Gro9JP05bH4=",
            "DNI": "23853757",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Sumire",
            "APELLIDO": "Calero cáceres ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Gris rallado ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Americana pelo corto",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1421218800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "1ADC95012341A40DA5E75247B22DA250.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "BDlzxoSmHJI=",
            "DNI": "82673492",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Gonzalo",
            "APELLIDO": "Proaño",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Naranja con rayas ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Americana pelo corto",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1509519600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "FE61F09719828F0782160A602D2BDD19.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "P65pxx0ycMQ=",
            "DNI": "76703850",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Dami",
            "APELLIDO": "Espinoza",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Siames",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1536044400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "05016D42450D2A5AA62105CD48DC8CE3.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "xxPIQSpU1jM=",
            "DNI": "48273393",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Vaqui",
            "APELLIDO": "Quispe Paitampoma",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "blanco y negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Criolla",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1531724400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "58CCC1AE8DD45033F0B02E241FC3E17F.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "XLSvwYrpvQ8=",
            "DNI": "33202522",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Misha",
            "APELLIDO": "Batallanos Humeres",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negra con manchas",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Criolla",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1660719600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "2161D2FACD67E7332020BA85FA09EE62.png",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "kaOn03SPDhs=",
            "DNI": "55878733",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Zeus Adonis",
            "APELLIDO": "Ramírez Goicochea ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco con rayas plomas  ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Siames Cruzado",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1580022000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "941F3EDE5151684F863EB878E1D3E892.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "EQ9D6X6q1iI=",
            "DNI": "00002326",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Muchi",
            "APELLIDO": "Gonzales Farfan",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Beige",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Siames Cruzado",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1230620400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "rwOCXcnNwfA=",
            "DNI": "70322799",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Luna",
            "APELLIDO": "Saco Surco",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco con gris",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Siames Cruzado",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1429858800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "WZGlQA7ebU0=",
            "DNI": "91838163",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Leo",
            "APELLIDO": "Gutiérrez Ramirez",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Naranja",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "MEZTIZO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1627542000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "1E35E07E09333A54A2B79FA0EA4707B7.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "s3vITXDAXIA=",
            "DNI": "39116852",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "EMILIO ",
            "APELLIDO": "RAMIREZ ALEGRE",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "PLOMISO AZULADO ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Azul Ruso",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1578121200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "FBF8DAE56F29A6287609141B633941FF.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "JGplN5PqoKY=",
            "DNI": "00001255",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Mikela",
            "APELLIDO": "Blanco",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Atigrado",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Americano de pelo co",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1446534000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "cZdeEV+g/MU=",
            "DNI": "84941222",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "BILLS ",
            "APELLIDO": "SANCHEZ ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "MOSTAZA CON RAYAS NEGRAS ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Criollo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1521097200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "7859DF91D0151AA6B404DE12B8362300.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "zOj1QylCmTI=",
            "DNI": "93223131",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Simba",
            "APELLIDO": "Bulnes Castañeda",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Anaranjado con rayas blancas",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Criollo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1545375600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "488B05CD294D1935113C5868FF28ECCD.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "pCTaGxMaHq4=",
            "DNI": "13329462",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Pitti",
            "APELLIDO": "Bautista Rojas",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Criollo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1495868400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "3CD5B730D6B4C1521B7A7CFAD50D7745.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "pkUny+5lTB0=",
            "DNI": "26627598",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Rifi",
            "APELLIDO": "Ulloa Luna",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Plomo y Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Unica",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1195110000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "DE28ABA3CD9F4B5D29803366A86229F8.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "Opm7pt/Nnlg=",
            "DNI": "96592364",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Lucas",
            "APELLIDO": "Vargas Pinedo",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Atigrado",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Americana tigrada",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1491289200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "168A051C1147D504F832AC8A0A1EBDD1.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "60vYlzIt1C8=",
            "DNI": "56322980",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Shakur Lito Fernando",
            "APELLIDO": "Torres Lezama",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Tricolor (negro, mostaza y blanco)",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Americana tigrada",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1537167600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "B7481497AC4D62E47BD9FB8E6AA6B45A.png",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "8Ppsr/RqnD4=",
            "DNI": "00009669",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "gringa",
            "APELLIDO": "rojas",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Gris",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Persa peke face",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1513321200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "TDzQYzzyRAY=",
            "DNI": "77149168",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "MININA",
            "APELLIDO": "ROUS",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "ANARANJADO,  NEGRO Y BLANCO ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "chusca",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1548226800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "byIEtD4ydUU=",
            "DNI": "00005419",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "MONA",
            "APELLIDO": "SOTO BRAVO",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "CHUSQUITA",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1271142000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "wfiK9VBt19E=",
            "DNI": "00008426",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "RITO",
            "APELLIDO": "PORTOCARRERO PRIETO",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "persa",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1517382000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "Zl7jGFUR4PI=",
            "DNI": "47561892",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Janneko ",
            "APELLIDO": "Gringasho Plomasha ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Beige, Blanco, Plomo, Negro ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "noble",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1512716400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "F950866095596DB1D471FFBC7694FB0A.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "bq95ZaXJh3g=",
            "DNI": "00007666",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "GRINGO",
            "APELLIDO": "DIAZ DURAND",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "@AMARILLO CON MANCHA",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "GATITO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1493708400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "YubRaG/QAG4=",
            "DNI": "00007973",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "MAX",
            "APELLIDO": "PERALTA MENDOZA",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Gris",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "RUSO AZUL",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1508655600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "CIXjfUNuycA=",
            "DNI": "29643768",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Preciosa",
            "APELLIDO": "Ricra Montoya",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestiza con bombay",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1541228400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "0C5D6C34154ABB43427D3FA247A396B7.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "dQgE+yo4Q7s=",
            "DNI": "52818628",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Boca Lucifer",
            "APELLIDO": "Llaque Padilla",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestiza con bombay",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1455087600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "E0865C06F10FB8517199F40C44C781A6.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "qZ/YtBUJcAI=",
            "DNI": "73959615",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Papi",
            "APELLIDO": "Geronimo Fernandez",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Negro",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1494054000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "4EED1153C6579C3F4D406DA8B34FC1D7.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "qX1kDqwWIQE=",
            "DNI": "13315964",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Olaf Gael ",
            "APELLIDO": "Manrique Granados ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Gris con marrón ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Siamés",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1574406000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "543AAEA0B0BBEDD345E5220ED6E10743.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "9O/2qjO4afQ=",
            "DNI": "36143349",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Cristalina",
            "APELLIDO": "Salas Rujel",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "plomo con marrón",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Siamés",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1497337200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "EC01A34F7FC3B03448CC52F2A89D52E8.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "VoXUheiqx+s=",
            "DNI": "03515192",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "LUCAS",
            "APELLIDO": "CHINO CAMA",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Siamés",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1556694000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "AD798D9BDD2DB848FDBE9C6649502F36.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "uYDNTLktFKU=",
            "DNI": "03067309",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Samuel Antonio ",
            "APELLIDO": "Moreau Escudero ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco/Gris",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Siamés",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1567407600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "59E71F918BB65F922A5293DB81A502ED.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "Ruv4axC1luc=",
            "DNI": "07423113",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Pecas",
            "APELLIDO": "GONZALES PERALTA",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "BLANCO CON GRIS",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Siamés",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1561014000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "377A54EE089B6809E8E68D75D28614CB.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "EeLIKVLgUQE=",
            "DNI": "72374107",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Naira",
            "APELLIDO": "Mejía Yóplac",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco con machas marrones",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Calicó",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1374562800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "BF548113FB07D1D1F766640A95D16845.png",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "rtvSJs8/Yxg=",
            "DNI": "26602619",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Eros",
            "APELLIDO": "Ocampo",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "gato",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1570950000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "3C698034BF38AF698FEF6476DA642FC9.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "cLUO9qgqH8s=",
            "DNI": "90253405",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Chimuelo ",
            "APELLIDO": "Chacon Mestanza ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "gato",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1620198000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "B2BF89D5C78B37E3B3192FB420DFBDCE.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "wGaUynQh5+4=",
            "DNI": "00009503",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "PANTERA",
            "APELLIDO": "ROJAS",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "gato",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(-62135571600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "cy+db3awc7w=",
            "DNI": "92013887",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Dante",
            "APELLIDO": "Medina goycochea",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco moteado",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Domestico",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1482303600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "pM5ZtlG+TbM=",
            "DNI": "08393224",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Gringuis",
            "APELLIDO": "Huaman",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Domestico",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1583046000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "83FC2CC3304417AC0ABF4A258507EE67.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "UKgmWf3qwpQ=",
            "DNI": "34077404",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Stuart Papucho ",
            "APELLIDO": "Custodio Trujillo ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco con manchas plomo oscuro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Domestico",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1338793200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "72FF247339A16A5E0850F7801504EAD1.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "q9YaySWCE8o=",
            "DNI": "38861654",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Leo",
            "APELLIDO": "Melgar Siliceo ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Gris",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "TIGRILLO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1410073200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "E6EC91CBA600CA785D5E02BEB0D0C8EB.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "1P45ye5lF/M=",
            "DNI": "62263719",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Morty",
            "APELLIDO": "Quispe",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro, Beige, Naranja",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "TRICOLOR",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1569913200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "14B3ACA497D396CC90401ECC40D29C5B.PNG",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "IjLDTnTU7KI=",
            "DNI": "00005220",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "NINA",
            "APELLIDO": "PONTE LEON",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "vacio_",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "CRUZADA CON ANGORA",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1525503600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "N82bsfnM0Ic=",
            "DNI": "24571379",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Rere",
            "APELLIDO": "Reee",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Rojo",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "AMERICA",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1562050800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "qk9sKxBNXIk=",
            "DNI": "00006268",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "chino",
            "APELLIDO": "vega",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Tabby",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1431414000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "z1BfzHJvDHE=",
            "DNI": "00006829",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "minina",
            "APELLIDO": "",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "vacio_",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "aleman",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1508223600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "pVULLPe2Lk8=",
            "DNI": "41254915",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Nina",
            "APELLIDO": "Albertis",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "rubio y blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestiza",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1513753200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "9272A3621010874766CBC8C1DBDF8B53.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "NJKbcaPDsWg=",
            "DNI": "10234130",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Mosha",
            "APELLIDO": "Zegarra ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mestiza",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1483858800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "84E84640ED1B31F3046CA6B905542217.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "v3n5TGLC0ek=",
            "DNI": "00008758",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "BLACK",
            "APELLIDO": "ESPINOZA YACOLCA",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1478934000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "WnJeGkt5hT4=",
            "DNI": "00007683",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Mio",
            "APELLIDO": "Coral",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "@Naranja",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1465974000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "tIAienkrWpg=",
            "DNI": "00010485",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "martin",
            "APELLIDO": "carmona",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Atigrado",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1469689200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "5ZaOHvnRg50=",
            "DNI": "00010878",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "PELUSO",
            "APELLIDO": "Casas Araujo",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1381388400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "Qyg58vxLwI0=",
            "DNI": "00010157",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "charlie",
            "APELLIDO": "zapata",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "@plomo",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1206342000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "krb3pYA+yvY=",
            "DNI": "00011134",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "PELUCHIN",
            "APELLIDO": "COSTALES LIMAY",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Amarillo",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1375945200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "itvcZFxr+io=",
            "DNI": "00005452",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Richi",
            "APELLIDO": "Acero bayoma",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Amarillo",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1562050800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "gYuWtn8bZ2s=",
            "DNI": "00005713",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "bony",
            "APELLIDO": "leyva cortina",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1501570800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "sJX5hafLOpo=",
            "DNI": "49103725",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "YUKI MARIE",
            "APELLIDO": "GARCIA FLORES GUERRA",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1609484400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "6A74872736C9F978B9FE57A7866AF2EE.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "f7SkG5fWxzk=",
            "DNI": "34175670",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Mawai",
            "APELLIDO": "Levano Tocas",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Tricolor",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1611471600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "5DFD282472662979AA5CABE8E4B54A13.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "N8t1XP1YA3g=",
            "DNI": "26222055",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Abner",
            "APELLIDO": "Velasquez Morrel",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1612162800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "92FAB3458EC3DAE48BE90B8C2AFE69CA.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "GR9U0fsmhjw=",
            "DNI": "98301808",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Midas ",
            "APELLIDO": "Gamarra Soto",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro \u0026 Plomo ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Cruzado",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1428735600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "0A743FA0DE869F27F06D695734781401.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "8jaHu/iFOrQ=",
            "DNI": "02222979",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Oseías",
            "APELLIDO": "Cabezas",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco y negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "cruce de angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1494399600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "41EE3D54F7E48B58026E65F2E4AA665A.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "kMVJvlvx2ac=",
            "DNI": "50668051",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Nico",
            "APELLIDO": "Silvestre Andia",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Plomo",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "cruce de angora",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1547708400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "B1152424D9FB3019D63552C1156B04BE.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "LJzFMU5qT0o=",
            "DNI": "89694156",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Drako",
            "APELLIDO": "Vivar",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "bombay",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1603954800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "4BD762EB3D5A37981EAA26AB1BD0F0B5.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "1aG2SABP5eU=",
            "DNI": "88702922",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Black Jack",
            "APELLIDO": "Del Aguila Muñoz",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "bombay",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1554879600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "E863E61A39E954AAF3D06C1134176C80.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "lTA3cUslfVQ=",
            "DNI": "58195376",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Luna",
            "APELLIDO": "Rivero Sánchez",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro azabache",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "bombay",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1543820400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "D32E8C4BBC61BF2411D56394B9D977FD.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "EOZNYR3N7ks=",
            "DNI": "70480095",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Telonius ",
            "APELLIDO": "Velásquez zuñiga",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Negro",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1513234800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "EC6FD6E97FF1F514BD69D90C6FBEC5CE.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "hY4vQMi5X4I=",
            "DNI": "64080732",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Elton Copito",
            "APELLIDO": "Ramos Luyken",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Gato Manx",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1585810800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "2E578B07493AE2D69A1DC66C46BF872E.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "KtLW46k9Is8=",
            "DNI": "27038802",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Facundo",
            "APELLIDO": "Proaño ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Naranja c/ manchas en círculo",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Americano Pelo Corto",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1509519600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "8C47DDF65C841360A4E8C17EC087BB63.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "MtQDYechUes=",
            "DNI": "93204088",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Chimuelo ",
            "APELLIDO": "Junchaya Salazar ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Peludo atigrado ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Americano Pelo Corto",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1592722800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "B9BD3871728F24D2E3780BC255867A34.PNG",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "aNx13WDrGwA=",
            "DNI": "00010859",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Gilbert",
            "APELLIDO": "ROJAS SALAS",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Gris",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Azul Ruso",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1275634800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "8DFW/q0iLF4=",
            "DNI": "00006101",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "lola",
            "APELLIDO": "ruiz monteiro",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Naranja",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "american",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1325401200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "+OvAg0PCVGY=",
            "DNI": "32647516",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Ozi",
            "APELLIDO": "Cordova quispe ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Plomo con blanco ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Azul Británico",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1543647600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "CE1863F25B15264712A4C35F06A72A28.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "llNH+CD2csg=",
            "DNI": "92327335",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Kitty",
            "APELLIDO": "Cajavilca Yengle",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "gris",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Azul Británico",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1428562800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "5159A7B434755D9BB3D0041E46E26F6A.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "9HcJfvJaNKo=",
            "DNI": "53944324",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Kitty",
            "APELLIDO": "Cajavilca Molina",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "gris",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Azul Británico",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1428562800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "972C16F442EBA60469680BF869DD6A89.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "XNbFDOShSFc=",
            "DNI": "00006392",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "ROKY",
            "APELLIDO": "SANCHEZ PILCO",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "@PLOMO",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Azul Británico",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1471503600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "kchrvkFVC7k=",
            "DNI": "00007430",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "ROMINA",
            "APELLIDO": "CHAVEZ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Tricolor",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ANGORA CRUZADA",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1218351600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "cSNXOzjJyX0=",
            "DNI": "88125101",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Derian",
            "APELLIDO": "Cavero Segura",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Beige con blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ANGORA CRUZADA",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1669964400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "D00174A7B88241F58E8DF2D4FA6F4271.png",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "QpPFm4HAp8w=",
            "DNI": "92887501",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "MINSU",
            "APELLIDO": "ZARATE  SÁNCHEZ ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Crema",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ANGORA CRUZADA",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1580108400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "86608D2E8DD7E51B9D936DD8303BDA8A.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "Wdecsx/gAa8=",
            "DNI": "77789763",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "BBUDY",
            "APELLIDO": "OLIVERA MORALES",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "BLANCO Y NEGRO",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ANGORA CRUZADA",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1575010800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "D6B86155EAF97C9D610E44C7AC20D3FA.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "BlHKZRAaui0=",
            "DNI": "00008375",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "HISUI",
            "APELLIDO": "AGUINO ARMAS",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "vacio_",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "RAYITO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1518678000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "XJNyA1UeISE=",
            "DNI": "28749494",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Batman",
            "APELLIDO": "Pulache Malca",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco c/manchas medianas, cabeza y cola atigrada (fondo negro con rayas de color beige)",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ATIGRADO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1534402800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "473C899B8E962BEA8B10D3A426614E0D.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "drSPD1YkasI=",
            "DNI": "99632194",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Yashiro Gnar",
            "APELLIDO": "Yatogami Villantoy",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Naranja",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ATIGRADO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1550214000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "65196AA6B3BB88ACA916771CC6AE88DF.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "U83Gh4+K8Xs=",
            "DNI": "81637270",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Tomas Alberto",
            "APELLIDO": "López Rufino ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Tricolor o tabby",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ATIGRADO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1531810800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "B542875163917DEE95A8347899022812.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "BdvQXoznJ3c=",
            "DNI": "78787454",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Titi",
            "APELLIDO": "Melchor Nuñez",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Mostaza, Negra y plomo",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ATIGRADO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1501657200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "D886159E152666B1DE8AD3975512D326.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "qtSd6w/2kl0=",
            "DNI": "19543665",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "TIGRE MININO ",
            "APELLIDO": "VALLADOLID TORRES",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "ANARANJADO CON BLANCO ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ATIGRADO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1565679600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "a+IXgB1nuI8=",
            "DNI": "55883250",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Micu",
            "APELLIDO": "Gutiérrez Villarroel",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "negro/gris/",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ATIGRADO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1454396400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "4ABEF40ACDE72E6A887BE4F0BCC4AA38.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "8CemYhNff8k=",
            "DNI": "00640224",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Negra",
            "APELLIDO": "Gimenez Vargas",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Pardo",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "CAREY",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1487401200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "aKSlLNT6vVs=",
            "DNI": "86529303",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "tigra kira",
            "APELLIDO": "albujar moscoso",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "tricolor",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "CAREY",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1413529200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "F9B1E5D4490B1F0AB318E994D61C3E5F.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "jPQID6rxlRY=",
            "DNI": "00009043",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "BINZ",
            "APELLIDO": "MOLINA MARIñÓN",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Gris",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "CARTUJO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1297666800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "0E+MIm7SoEc=",
            "DNI": "21351381",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "CLEOPATRA",
            "APELLIDO": "FLORES ZURITA ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "BLANCO CON FRANJITAS NEGRAS",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "AMERICAN WIREHAIR",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1339743600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "DE95F34C145BEE0A02519377F2B2C74B.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "aSOlLRlQa0g=",
            "DNI": "00004046",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "simba",
            "APELLIDO": "carazas nunta",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "bombay",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1407481200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "Mx7mpxtnu5k=",
            "DNI": "97009251",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Michilín",
            "APELLIDO": "Carrasco",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Amarillo atigrado",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "chusco",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1254207600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "08CF94E3112AA2C3F1AD84CA940E1264.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "syFWVfXH2K4=",
            "DNI": "91721887",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "ODESSA",
            "APELLIDO": "OLIVERA HERNANDEZ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "ATIGRADO ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Mixta",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1561014000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "B8B35CB0AE1719AA9F0BA7A9F3785687.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "2t248aCwwOs=",
            "DNI": "14953727",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Tom",
            "APELLIDO": "Figueroa ",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Gris con Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "AMERICANO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1571986800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "AFED162552A9AF44047EC8EC3C1BED65.JPG",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "rqSjvGSRP6I=",
            "DNI": "00007393",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "LUNA",
            "APELLIDO": "TORRES CABRERA",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "@crema",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "PERZA",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1536130800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "kF3Ykc6usqU=",
            "DNI": "00807518",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Snowing",
            "APELLIDO": "Arroyo Guevara",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Atigrado",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "CRIOLLOS",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1452841200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "16F8455FFEE11D28C48DCA19D52AB537.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "zrCIYq4as7E=",
            "DNI": "57334725",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Tigger",
            "APELLIDO": "Soto",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Marron con Blanco ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "UNICO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1435647600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "D942D71D77B0FD96F086B86A806F9A02.png",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "jjC9RnkSWd4=",
            "DNI": "63060315",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Lucas",
            "APELLIDO": "Lazo Zárate",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Dorado/naranja",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "UNICO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1471849200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "FE7212D1AD2166E6366228E01E1A258E.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "j5+FreB7E5M=",
            "DNI": "68344789",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Chorito",
            "APELLIDO": "Arias Salvador",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco con tonos grises",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "UNICO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1516258800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "C677EC07C9679C2445E251CA89702D8F.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "/owWoxMvVQU=",
            "DNI": "42749127",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Oso",
            "APELLIDO": "Paz valladolid",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Bieg",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "pelo largo americano",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1266303600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "9C2BEE71286D52B7611051A8229B911B.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "xTyGU3B4eRs=",
            "DNI": "96024341",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Bloo Ámbar",
            "APELLIDO": "huamán huamán",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco y Naranja",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Van Turco Cruzado",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1565938800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "044F91D1A6CA41A346CD1ABC75767B31.PNG",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "K/ubteiZFk4=",
            "DNI": "17495216",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "baquita",
            "APELLIDO": "advincula",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Negro y blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Británico Bicolor",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1514790000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "E290F24974BB79ECE2EE2718EB0B45CB.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "jjFDXTU0XbA=",
            "DNI": "05476527",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Simba Tiger Omalie",
            "APELLIDO": "Flores Goicochea",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Naranja atigrado",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Gato naranja",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1485846000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "89113BDE06950FEDE1C11C346EA78046.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "2gF81M4cW+c=",
            "DNI": "57759774",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "SILVER",
            "APELLIDO": "AYALA CUBAS",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Plomo",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Europeo shorthair",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1621148400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "E905780C3DC9DC1293A36F0C0E73A0EF.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "G7y90DgFUFE=",
            "DNI": "72254713",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Paco Asrael ",
            "APELLIDO": "Ortega Alanya",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "plomo",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ATIGRADO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1567321200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "47C163FDD17555A113D90BE51BA939CC.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "E+jH5lAllME=",
            "DNI": "70973909",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Simba",
            "APELLIDO": "Monchon Barreto",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Naranja con blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ATIGRADO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1565938800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "E6C879728BB6947910F567B8D96437B0.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "A5pcG+zA1AQ=",
            "DNI": "38613562",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Luis Miau Leónidas Nicolás",
            "APELLIDO": "Virrueta Casas",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Gris, Negro y Marrón",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ATIGRADO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1622962800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "394BDAA1B0CFDEC3E4CE14D6CCE188A6.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "CC0gfaknlWA=",
            "DNI": "05411847",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Aslan ",
            "APELLIDO": "Cancho de la cruz",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Oscuro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "ATIGRADO",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1512975600000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "72BC619341A7327385A743589E9D794F.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "8KstnMjbq5g=",
            "DNI": "36463118",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Pompon",
            "APELLIDO": "Panez Pablo",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora/Siames",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1533625200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "419E4410DA152C74D727270283CB94CE.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "n38xbeski4w=",
            "DNI": "34892127",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Gaturry",
            "APELLIDO": "Palacin",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Crema y Marrón",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora/Siames",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1484377200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "DE04B69DC1DD87F188C8F52B7A57A628.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Hembra",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "gthKs7M8knw=",
            "DNI": "36766304",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Mini",
            "APELLIDO": "Visalot",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Beige",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Angora/Siames",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1494745200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "209606E4460C1048ED2775928B81B2D0.PNG",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "jiUCXApMz/k=",
            "DNI": "03954861",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Mico",
            "APELLIDO": "Reyes",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco y negro",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "tuxedo",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1499497200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "AD96D4CFA75C922010041F8C2A51D098.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "byNMes+eq6k=",
            "DNI": "00012470",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Mew",
            "APELLIDO": "Garatea",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Atigrado",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Orange tabby cat",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1470034800000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "Rqk5yu26P7E=",
            "DNI": "57081908",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Nick ",
            "APELLIDO": "Huaroc Mello",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "amarillo mostaza",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Orange tabby cat",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1574492400000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "BD191B1A775F40FD37062504B22BF5EC.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "d//KJyQL7Ho=",
            "DNI": "60887257",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Michi",
            "APELLIDO": "Martinez Apesteguía",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Naranja atigrado  ",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Orange tabby cat",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1561273200000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "C7750D91FBFCCDD481410AD8F0F6AFD9.jpg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        },
        {
            "__type": "ENTIDAD.EMascota",
            "TAMANO": "Grande",
            "TIPO": null,
            "RAZA": null,
            "CALIFICACION": null,
            "SEXO": "Macho",
            "CORREO": null,
            "OBSERVACION": null,
            "TELEFONO": null,
            "CELULAR": null,
            "ID": 0,
            "ID_ENCRIP": "ELWdSZ2KN9E=",
            "DNI": "94643380",
            "USUARIO_ID": 0,
            "USUARIO": 0,
            "NOMBRE": "Albin",
            "APELLIDO": "Diaz Pereda",
            "COD_MICROCHIP": null,
            "ESPECIE": null,
            "MASCOTA_TIPO_ID": 0,
            "MASCOTA_RAZA_ID": 0,
            "COLOR": "Blanco",
            "TIPO_DSC": "FELINO",
            "RAZA_DSC": "Khao Manee",
            "BIOGRAFIA": null,
            "FEC_NAC": "\/Date(1550214000000)\/",
            "FEC_CREA": null,
            "FEC_EMI": null,
            "FEC_CAD": null,
            "FAMILIARP": null,
            "DNIP": null,
            "TELEFONOP": null,
            "FAMILIARM": null,
            "DNIM": null,
            "TELEFONOM": null,
            "GEOGRAFIA_ID": 0,
            "DEPARTAMENTO": null,
            "PROVINCIA": null,
            "DISTRITO": null,
            "DIRECCION": null,
            "PISO": null,
            "REFERENCIA": null,
            "GALERIA_ID": 0,
            "FOTO": "A6BCA7793C76D7C39F4CF4A4787524A8.jpeg",
            "EXTENSION": null,
            "CASTRADO": 0,
            "VISITA": 0,
            "ALERGIA_MEDICAMENTO": 0,
            "VACUNACION": 0,
            "ANTIRRABICA": 0,
            "FEC_ANTIRRABICA": null,
            "ALERGIA": 0,
            "ALERGIA_DSC": null,
            "ENFERMEDAD": 0,
            "ENFERMEDAD_DSC": null,
            "FEC_DESPARACITACION": null,
            "SEXTUPLE": 0,
            "FEC_QUINTUPLE": null,
            "QUINTUPLE": 0,
            "FEC_SEXTUPLE": null,
            "TRIPLEFEL": 0,
            "FEC_TRIPLEFEL": null,
            "LEUCEMIA": 0,
            "FEC_LEUCEMIA": null,
            "LIMP_DENTAL": 0,
            "OPCION": 0,
            "ESTADO": 0,
            "lMASCOTA": null,
            "NOMBRE_PRE": null,
            "APELLIDO_PRE": null,
            "PASSWORD": null,
            "MES": 0,
            "INDICE": 0,
            "FEC_INI": null,
            "FEC_FIN": null
        }
    ]     