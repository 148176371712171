import "./style.css";
import productDetailsImg from "../../assets/images/product_details.png";
import {ovalIcon2} from "../../assets/svgIcons";
import {useEffect, useState} from "react";
import img1 from "../../assets/images/Screenshot_2024-08-29_165705-removebg-preview.png";
import img2 from "../../assets/images/Screenshot_2024-08-29_165627-removebg-preview.png";
import {AccordionItem} from "react-bootstrap";
import ProductDetailAccordian from "../../components/ProductDetailAccordian/ProductDetailAccordian";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  addOneToCart,
  addToCart,
  minusFromCart,
  removeFromCart,
} from "../../redux/cartSlice";
import toast from "react-hot-toast";

export default function ProductDetails() {
  const [number, setNumber] = useState(0);
  const dispatch = useDispatch();

  const cartData = useSelector((state) => state.cart.cart);
  const location = useLocation();
  const [isInCart, setIsInCart] = useState(false);
  const {data} = location?.state;
  useEffect(() => {
    console.log(data);
  }, [data]);

  function handleIncrease() {
    if (isInCart) {
      dispatch(addOneToCart(data));
      toast.success("Agregado al carrito exitosamente");
      return;
    }

    setNumber((prevState) => prevState + 1);
  }

  useEffect(() => {
    console.log(cartData);
    const doesExist = cartData.find((cartProd) => cartProd.id == data.id);
    if (doesExist) {
      setIsInCart(true);
    } else {
      setIsInCart(false);
      setNumber(0)
    }
  }, [cartData]);

  function handleDecrease() {
    const countInCart = cartData.find((item) => item.id == data.id)?.count;

    if (+countInCart <= 1) {
      dispatch(removeFromCart(data));
      //   toast.success("Eliminado correctamente del carrito.");
      toast.success("DELETE");
      setNumber(0);
      return;
    }
    if (isInCart) {
      dispatch(minusFromCart(data));
      toast.success("Eliminado correctamente del carrito.");
      return;
    }
    if (number < 1) {
      return;
    }
    setNumber((prevState) => prevState - 1);
  }

  const handleAddToCart = async () => {
    if (number == 0) {
      toast.error("Introduce el número del producto.");
      return;
    }
    
    if (isInCart) {
        dispatch(removeFromCart(data));
        toast.success("Eliminado correctamente del carrito.");
        setNumber(0);
      return;
    }

    const dataset = {
      ...data,
      count: number,
    };
    dispatch(addToCart(dataset));
    toast.success("Agregado al carrito exitosamente");
  };

  return (
    <div className='product_details_page'>
      <div className='product_details_container'>
        <div className='product_details_img'>
          <img src={data?.image} />
        </div>

        <div className='product_details_text_container'>
          <h2 className='product_details_title'>
            <img src={img1} />
            <span>{data.prodName}</span>
            <img src={img2} />
          </h2>

          <ul>
            <div>
              <li>DNI ultra resistente</li>
              <li>Plaquita grabada a laser</li>
              <li>Collar decorativo (hasta 45 cm)</li>
            </div>
            <div>
              <li>Certificado de Dueño Responsable</li>
              <li>Certificado de Registro</li>
            </div>
          </ul>

          <p>
            ¡Evita que tu mascota se pierda! Con esta oferta tendrás una
            cobertura inicial que le permitirá a tu mascotita ser identificada
            en caso de pérdida y permitirá que te contacten para coordinar el
            regreso de tu engreido a tu hogar
          </p>

          <ProductDetailAccordian />

          <div className='product_details_text_Incluye'>
            <p>S/ {data.price}</p>
            <p>Incluye boleta o factura</p>
          </div>

          <div className='product_details_Comprar'>
            <div className='product_details_number'>
              <span onClick={handleDecrease}>-</span>
              <div>
                {cartData.find((item) => item.id == data.id)?.count || number}
              </div>
              <span onClick={handleIncrease}>+</span>
            </div>
            <div
              onClick={() => handleAddToCart()}
              className='oval_button os_oval text-center'
              style={{
                position: "relative",
                fill: "rgb(248, 100, 8)",
              }}
            >
              {ovalIcon2}

              <div className='oval_btn_text'>
                {isInCart ? "Eliminación" : "Comprar"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
