import React, {useState, useRef, useEffect} from "react";
import theySay from "../../../assets/images/theySay.png";
import "./style.css";


import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css/navigation";
import "swiper/css";

// import "../../HomePage/NotiRump/swiperStyle.css";
import "swiper/css/pagination";

// import required modules
import {EffectCoverflow, Navigation, Pagination} from "swiper/modules";
import {LazyLoadImage} from "react-lazy-load-image-component";

import "swiper/css/effect-coverflow";

import say_1 from "../../../assets/images/say_1.png";
import say_2 from "../../../assets/images/say_2.png";
import say_3 from "../../../assets/images/say_3.png";
import say_4 from "../../../assets/images/say_4.png";
import say_5 from "../../../assets/images/say_5.png";
import {arrowLeft, arrowRight} from "../../../assets/svgIcons";

import dogAndEarth from "../../../assets/images/dog_catch_earth.png";

import AOS from 'aos';

const TheySay = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const handleSlideChange = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      setActiveIndex(swiperRef.current.swiper.activeIndex);
      console.log(swiperRef.current.swiper.activeIndex);
    }

  };


  useEffect(()=>{
    AOS.init({
      duration:600,
    })
  },[])

  const saying = [
    {
      id: 0,
      text: `RUMP es la mejor tienda de mascotas en la ciudad. Tienen todo lo que mis peces necesitan y siempre encuentro nuevos accesorios interesantes. El personal es muy conocedor y me ha dado excelentes consejos.`,
     
      name: "Familia de Mylan",
      image: say_1,
    },
    {
      id: 1,
      text: `Cada vez que voy a RUMP, salgo con una gran sonrisa. Tienen una increíble selección de productos para mascotas y siempre encuentro algo nuevo para mis conejos. El ambiente es muy agradable y limpio`,
      name: " María López",
      image: say_2,
    },
    {
      id: 2,
      text: `¡RUMP es increíble! Encuentro todo lo que necesito para mi perro, desde comida de alta calidad hasta juguetes duraderos. El personal es muy amable y siempre está dispuesto a ayudar. ¡Lo recomiendo al 100%!`,
      name: "Laura Martínez",
      image: say_3,
    },
    {
      id: 3,
      text: `Descubrí RUMP hace unos meses y me ha encantado. Tienen una gran variedad de productos y siempre hay ofertas interesantes. Mis gatos están muy contentos con sus nuevos juguetes y camas.`,
      name: "Carlos García",
      image: say_4,
    },
    {
      id: 4,


      text: <div> Gracias a RUMP <span className="text-dark"> sabemos
      que nuestro Mylan está
      más seguro.</span> Además, los
      productos están hermosos</div>,
      name: "José Fernández",
      image: say_5,
    },
  ];

  return (
    <div className='TheySayContainer'>
      <div className='text-center th_title' data-aos = 'fade-up'>
        <img src={theySay} alt='' />
      </div>

      <div className='they_say_content'>
        <div className='ts_left' data-aos = 'fade-right'>
          <>
            <Swiper
              ref={swiperRef}
              onSlideChange={handleSlideChange}
              loopFillGroupWithBlank={true}
              // ------------------------
              initialSlide={2}
              // effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"2"}
              // pagination={true}
              spaceBetween={35}
              // loop={true}
              navigation={{
                nextEl: ".custom-next",
                prevEl: ".custom-prev",
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 1,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 1,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
              coverflowEffect={{
                rotate: 10,
                stretch: 50,
                depth: 200,
                modifier: 1,
              }}
              className='mySwiper'
            >
              {saying?.map((item, index) => {
                return (
                  <SwiperSlide className='swiper-slide'>
                    <div
                      className='th_image_slide'
                      style={
                        {
                          // paddingInline: "40px",
                        }
                      }
                    >
                      <img src={item.image} alt='' />
                      
                     
                    </div>
                  </SwiperSlide>
                );
              })}

              <div className='custom-navigation theySay'>
                <div className='custom-prev'>{arrowLeft}</div>
                <div className='custom-next'>{arrowRight}</div>
              </div>
            
            </Swiper>
          </>
        </div>
        <div className='ts_right' data-aos = 'fade-left'>
          <div className='saying'>
            {saying.filter((item) => activeIndex == item?.id)[0]?.text}
          </div>
          <div className='teller_name'>
            {saying.filter((item) => +activeIndex == +item?.id)[0]?.name}
          </div>
        </div>
      </div>

      <div className='dog_catch_earth_image'>
        <img src={dogAndEarth} alt='' />
      </div>
    </div>
  );
};

export default TheySay;
