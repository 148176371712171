import "./style.css";
import lostImg from "../../../assets/images/lost_dog_3.webp";
import cert_per from "../../../assets/images/cert_person.png";

export default function NoNeedLostPets() {
  return (
    <div className='no_need_container'>
      <div className='nn_title'>
        <p>No queremos más mascotas perdida</p>
        <p>y decidimos hacer un cambio</p>
      </div>

      <div className='no_need_page'>
        <img src={cert_per} />
        <div>
          <p className='first-text'>
            Tenemos el registro de mascotas más grande,
          </p>
          <h4>pero nosotros no compartiremos ni venderemos tus datos a</h4>

          <p>
            RUMP nació a finales de 2018 y ya contamos con más de 200 mil
            mascotitas registradas en todo el Perú
          </p>

          <p>
            Nuestro objetivo es reducir la tasa de abandono y usar la tecnología
            para que las mascotas tengan más probabilidades de volver a casa en
            caso de perderse.
          </p>

          <p>
            El Registro Único de Mascotas del Perú también permite conocer el
            estado actual de la población canina, felina y de otros compañeros
            de vida, asegurando bases sólidas para su control y bienestar
          </p>
        </div>
      </div>
    </div>
  );
}
