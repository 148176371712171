

import React from 'react'
import Preview from '../../components/StrarPage/Previwe/Preview'
import PetFood from './../../components/StrarPage/PetFood/PetFood';
import OwnerShip from '../../components/StrarPage/ownerShip/OwnerShip';
import StartFindPet from '../../components/StrarPage/startFindPet/startFindPet';
import LoveProof from '../../components/StrarPage/LoveProof/LoveProof';
import HowToGet from '../../components/StrarPage/HowToGet/HowToGet';
import EndSection from '../../components/StrarPage/EndSection/EndSection';
import TheySay from '../../components/StrarPage/TheySay/TheySay';
import "./style.css"


const StartPage = () => {
  return (
    <div className='start_page_container'>
      <Preview />
       <PetFood />
      <OwnerShip />
      <StartFindPet />
      <LoveProof />
      <HowToGet />
      <TheySay />
      <EndSection /> 
      
      
    </div>
  )
}

export default StartPage
