import React from "react";
import "./style.css";

import brackTrueCircel from "../../../assets/images/black true_circle.png";
import {
  healthIcon,
  offersIcon,
  partyicon,
  pawIcon,
  satelliteIcon,
} from "../../../assets/svgIcons";

const RejestrationBenifits = () => {
  return (
    <div className='rb_container'>
      <div className='rb_content'>
        <div className='rb_title'>
          <h4 className='text-white'>
            Los <span className='text-dark'>beneficios</span> de
          </h4>
          <h4 className='text-white'>registrarte con nosotros</h4>
        </div>

        <div className='benifits_container'>
          {[
            {
              id: 0,
              icon: pawIcon,
              title: "Info detallada de la mascota",
              info: `El usuario puede agregar toda la información detallada
                necesaria, tanto para el contacto de los responsables como la
                información médica de la mascota.`,
            },
            {
              id: 1,
              icon: healthIcon,
              title: "Historial de vacunas y tratamientos",
              info: `El usuario podra registrar eventos como citas médicas,
chequeos, visitas al spa, vacunas, etc. Así podrá llevar un
seguimiento del cuidado y los cambios en la salud de la
mascotita.`,
            },
            {
              id: 2,
              icon: offersIcon,
              title: "Descuentos y ofertas",
              info: `Nuestros convenios ofrecen descuentos y servicios a los
clientes que presenten su DNI RUMP vigente en los
establecimientos que tienen este acuerdo con nosotros`,
            },
            {
              id: 3,
              icon: satelliteIcon,
              title: "Innovación tecnológica",
              info: `Constantemente implementamos mejoras en nuestro
sistema de registro e identificación. Estamos trabajando
en nuevas actualizaciones nunca antes vistas en el
mercado nacional.`,
            },
            {
              id: 4,
              icon: partyicon,
              title: "Actividades, sorteos y eventos",
              info: `Participa de nuestros eventos, sorteos y actividades
virtuales y presenciales. Todos los meses desarrollamos
una dinámica. Escríbenos y consulta cuál está vigente.`,
            },
          ]?.map((item, index) => {
            return (
              <div key={index} className={"benifit"}>
                <div className='image_icon'>
                  <div className='image'>
                    <img src={brackTrueCircel} alt='' />
                  </div>
                  <div className='icon'>{item.icon}</div>
                </div>
                <div className='benifit_content'>
                  <div className='benifit_title'>{item.title}</div>
                  <div className='benifit_info'>{item.info}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RejestrationBenifits;
