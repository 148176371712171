import React, { useEffect, useState } from "react";
import "./style.css";
import A_letter from "../../assets/images/a_letter.png";
import fp_dog from "../../assets/images/findPet_dog.png";
import AOS from "aos";
import Modal from "../../components/Modal/Modal";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useMediaQuery } from "../../CustomHooks/useMediaQueries";
import FromGroup from "../../components/FromGroup/FromGroup";
import { FormGroup } from "react-bootstrap";

const FindPet = () => {
  const [isOpenModal , setIsOpenModal] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  useEffect(()=>{
    AOS.init({
      duration:600
    })
  },[]);


  return (
     <>
       
       <Modal className="findpet_modal" showCloseBtn size={isSmallScreen ?"100%" : "90%"} show={isOpenModal} onClose={() => setIsOpenModal(false)}>
         
       <div className='d-flex flex-column gap-4'>
              <FromGroup rowCount={3}>
                <FromGroup.Input
                  required={true}
                  label={"Nombres"}
                  placeholder='Nombre de la mascota'
                />
                <FromGroup.Input
                  required={true}
                  label={"Apellidos"}
                  placeholder='Apellidos del usuario'
                />
                <FromGroup.Input
                  required={true}
                  type={"date"}
                  label={"Fecha de nacimiento"}
                />
                {/*  */}
                {/* <CustomSelect
                  required={true}
                  label={"Sexo de la mascota"}
                  placeholder='Apellidos del usuario'
                /> */}
                <CustomSelect
                  required={true}
                  label={"¿La mascota está esterilizada?"}
                  placeholder='No'
                />
                <CustomSelect label={"Calificación "} required={true} />
                {/* <CustomSelect
                  required={true}
                  label={"Especie"}
                  placeholder='Seleccione'
                /> */}
                <CustomInput
                  required={true}
                  label={"Raza"}
                  placeholder='Seleccione'
                />
                <CustomInput
                  required={true}
                  label={"Color de pelaje"}
                  placeholder='Color de pelaje'
                />
              </FromGroup>

              {/*  */}

              {/* <FromGroup rowCount={1}>
                <CustomInput
                  required={true}
                  label={"Dirección"}
                  placeholder='Escriba la dirección...'
                />
              </FromGroup> */}

              <FromGroup rowCount={3}>
                <CustomSelect
                  required={true}
                  label={"Departamento"}
                  placeholder='Seleccione'
                />
                <CustomSelect
                  required={true}
                  label={"Provincia"}
                  placeholder='Seleccione'
                />
                <CustomSelect
                  required={true}
                  label={"Distrito"}
                  placeholder='Seleccione'
                />
              </FromGroup>
                
              <FromGroup rowCount={2}>
              <FromGroup.Input
                  required={true}
                  label={"número de teléfono 1"}
                  placeholder="número de teléfono 1"
                 />
                <FromGroup.Input
                  required={true}
                  label={"número de teléfono 2"}
                  placeholder="número de teléfono 2"
                />
              </FromGroup>
            </div>
         
         {/* <div className='d-flex flex-column gap-4'>
         <div className="form_group_container" style={{marginTop:"20px"}}>
           <form className="custom_form">
             <div className="custom_inputs_group" style={{gridTemplateColumns:"repeat(3,1fr)"}}>
               <div>
                 <label>Nombre <span>(*)</span></label>
                 <input type="text" placeholder="Nombre de la mascota"/>
               </div>

               <div>
                 <label>Apellidos <span>(*)</span></label>
                 <input type="text" placeholder="Apellidos del usuario"/>
               </div>

               <div>
                 <label>Fecha de nacimiento <span>(*)</span></label>
                 <input type="date" />
               </div>
             </div>
           </form>
         </div>

         <div className="form_group_container" style={{marginTop:"10px"}}>
           <form className="custom_form">
             <div className="custom_inputs_group" style={{gridTemplateColumns:"repeat(3,1fr)"}}>
               <div>
                
                 <CustomSelect  required={true}
                    label={"Esterilizado? "}
                    placeholder='Esterilizado?'
                    data={[
                      {
                        label:'Si',
                        value:'si'
                      },
                      {
                        label:"No",
                        value:"No"
                      }
                    ]}
                    />
               </div>

               <div>
                 <label>Raza (*)</label>
                 <input type="text" placeholder="Raza"/>
               </div>

               <div>
                 <label>Color de pelaje (*)</label>
                 <input type="text" placeholder="Color de pelaje"/>
               </div>
             </div>
           </form>
         </div>

         <div className="form_group_container" style={{marginTop:"10px"}}>
           <form className="custom_form">
             <div className="custom_inputs_group" style={{gridTemplateColumns:"repeat(3,1fr)"}}>
               <div>
                 <CustomSelect
                    required={true}
                    label={"Departamento"}
                    placeholder='Seleccione'
                    data={
                      [
                        {
                          label:'Amazon',
                          value:'Amazon'
                        },
                        {
                          label:'Apurimac',
                          value:'Apurimac'
                        },
                        {
                          label:'Arequpia',
                          value:'Arequpia'
                        },
                        {
                          label:'Ayacucho',
                          value:'Ayacucho'
                        },
                        {
                          label:'Cajamarca',
                          value:'Cajamarca'
                        },
                        {
                          label:'Callao',
                          value:'Callao'
                        },
                        {
                          label:'Cusco',
                          value:'Cusco'
                        },
                        {
                          label:'Huancavelica',
                          value:'Huancavelica'
                        },
                        {
                          label:'Huanuco',
                          value:'Huanuco'
                        },
                        {
                          label:'Ica',
                          value:'Ica'
                        },
                        {
                          label:'Junin',
                          value:'Junin'
                        },
                        {
                          label:'Freedom',
                          value:'Freedom'
                        },
                        {
                          label:'Lambayeque',
                          value:'Lambayeque'
                        },
                        {
                          label:'Lime',
                          value:'Lime'
                        },
                        {
                          label:'Loreto',
                          value:'Loreto'
                        },
                        {
                          label:'Mother of God',
                          value:'Mother of God'
                        },
                        {
                          label:'Moquegua',
                          value:'Moquegua'
                        },
                        {
                          label:'Pasco',
                          value:'Pasco'
                        },
                        {
                          label:'piura',
                          value:'piura'
                        },
                        {
                          label:'Fist',
                          value:'Fist'
                        },
                        {
                          label:'San Martin',
                          value:'Sam Martin'
                        },
                        {
                          label:'Tumbes',
                          value:'Tumbes'
                        },
                        {
                          label:'Ucayala',
                          value:'Ucayala'
                        },
                      ]
                    }
                  />
               </div>

               <div>  

<CustomSelect
                    required={true}
                    label={"Provincia"}
                    placeholder='Seleccione'
                    data={[
                      {
                        label:'Bagua',
                        value:'Bagua'
                      },
                      {
                        label:'Bongara',
                        value:'Bongara'
                      },
                      {
                        label:'Chachapoyas',
                        value:'Chachapoyas'
                      },
                      {
                        label:'Condorcanqui',
                        value:'Condorcanqui'
                      },
                      {
                        label:'Luya',
                        value:'Luya'
                      },
                      {
                        label:'Rodríguez de Mendoza',
                        value:'Rodríguez de Mendoza'
                      },
                      {
                        label:'Utcubamba',
                        value:'Utcubamba'
                      },
                      
                    ]}
                  />
                  </div> 

                 <div>
                  <CustomSelect
                    required={true}
                    label={"Distrito"}
                    placeholder='Seleccione'
                    data={[
                      {
                        value:'district1',
                        label:'district1',
                      },
                      {
                        value:'district2',
                        label:'district2',
                      }
                    ]}
                  />
               </div>
             </div>
           </form>
         </div>

         <div className="form_group_container" style={{marginTop:"10px"}}>
           <form className="custom_form">
             <div className="custom_inputs_group" style={{gridTemplateColumns:"repeat(3,1fr)"}}>
             <div>
            <CustomSelect label={"Calificación "} required={true}
                  
                  data={[
                    {
                      label:'Seleccione',
                      value:''
                    },
                    {
                      label:'Agresivo',
                      value:'Agresivo'
                    },
                    {
                      label:'Amistoso',
                      value:'Amistoso'
                    },
                    {
                      label:'Discapacitado',
                      value:'Discapacitado'
                    },
                    {
                      label:'Entrenado',
                      value:'Entrenado'
                    },
                    {
                      label:'Miedoso',
                      value:'Miedoso'
                    },
                    {
                      label:'Peleador',
                      value:'Peleador'
                    },
                  ]}
                  />
            </div>

            <div>
            <CustomInput
                    required={true}
                    label={"Distrito"}
                    placeholder='Seleccione'
                    type={"file"}
                  />
            </div>
               
             </div>
           </form>
         </div>
         </div> */}

         <div className='modal_buttons' style={{marginTop:"30px"}}>
              <button className='confirm_button'>GUARDAR</button>
              <button className='cancel_button' onClick={() => setIsOpenModal(false)}>Cerrar</button>
         </div>
       </Modal>

       <div className='findpet_container'>
      <div data-aos='zoom-out' className='fb_content'>
        <div className='fp_left_side'>
          <div className='image'>
            <img src={A_letter} loading='lazy' alt='' />
          </div>
          <div className='fb_jubject '>
            <div className='fb_title'>
              {" "}
              <span className="text_orange"> Encontré </span>una mascota
            </div>
            <p className='fb_info'>
              Ingresa el código RUMP o número de MICROCHIP para identificar y
              ubicar a los resaposables.
            </p>
          </div>
          

        </div>
        <div className='fp_right_side'>
          <div className='fp_dog_image'>
            <img src={fp_dog} alt='' />
          </div>
        </div>
      <div className="fb_abs_btn mx-auto" onClick={() => setIsOpenModal(true)}>
            Ingresa el código aquí
        </div>
      </div>

    </div>
     </>
  );
};

export default FindPet;
