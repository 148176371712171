import "./style.css";
import two_girls_images from "../../../assets/images/two_girls.png";

export default function AboutResponsibility() {
  return (
    <div className='about-responsibility-container'>
      <div className='about-responsibility-page'>
        <div className='about-responsibility-card'>Responsabilidad social</div>
        <h2>De la mano de rescatistas, albergues, veterinarios y PetLovers</h2>
        <p>
        Sabemos lo difícil que puede ser costear proyectos en favor de
los animales. Por ello nosotros ponemos nuestro registro a
disposición de estas iniciativas para que puedan obtener
recompensas por recomendar nuestros productos. Así
obtienen una fuente de ingresos adicional y pueden continuar
su grandiosa labor.
        </p>
        <p>
        También apoyamos con la participación en sus eventos, al
igual que la difusión, donando parte buena parte de lo
recaudado a proyectos animalistas.
        </p>
      </div>
      <img src={two_girls_images} />
    </div>
  );
}
